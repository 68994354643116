import React, {useState, useEffect} from 'react';
import './LoadAccount.css';
import ReloadAccountDropdown from '../Dropdowns/ReloadAccountDropdown/ReloadAccountDropdown'
import {getAuthenticationDataFromLocalStorage} from '../../Utilities/LocalStorage/UserAuthStorage'
import Button from '../AppButtons/Button/Button'
import {loadAccount, getUserInfo} from '../../Services/User'
import {connect} from 'react-redux'
import {scrollToTopOfPage} from '../../Utilities/DOMutils/DOMutils'
import {setCurrentUserAttribs} from '../../Actions/User/DispatchUserState'


const LoadAccount = props => {
  let [amount, setAmount] = useState(null)
  let [loading, setLoading] = useState(false)
  let [resultData, setResultData] = useState(null)
  let user = getAuthenticationDataFromLocalStorage()

  useEffect(() => {
    refetchUserData()
    scrollToTopOfPage()
  }, [])


  function handleCharge() {
    setLoading(true)
    loadAccount(amount.value).then(res => res.json())
    .then(obj => {
      setLoading(false)
      cleanUp()
      refetchUserData()
      if (obj.data) {
        setResultData(obj.data)
      } else {
        alert(obj.status)
      }
    })
  }

  function refetchUserData() {
    getUserInfo(user.user_id).then(res => res.json())
    .then(obj => {
      if (obj && obj.data && obj.data.type === 'user') {
        let currentUser = obj.data
        setCurrentUserAttribs(currentUser, props.refreshUserDetails)
      }
    })
  }

  function cleanUp() {
    setAmount(null)
  }

  function renderResult() {
    if (resultData) {
      return (
        <p className="phone-number-buy-success-info">Account Loaded!</p>
      )
    }
  }

  return (
    <div className="load-account-wrapper">
      <div className="load-account-heading-wrapper">
        <h1 className="load-account-heading">Load Account</h1>
      </div>
      <div className="load-account-dropdown-wrapper">
        <ReloadAccountDropdown onSelect={(e) => {setAmount(e) ; setResultData(null)}}/>
      </div>
      <div className="load-account-controls-wrapper">
        {renderResult()}
        <Button 
          disabled={amount && !loading ? false : true}
          title={amount ? `Load ${amount.label}` : "Load Account"}
          name={`next-button ${loading ? 'loading' : ''}`}
          clicked={handleCharge}
        />
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
  }
}

const mapDispatchToProps = dispatch => {
  return {
    refreshUserDetails: (userObj) => {dispatch({
      type: 'setCurrentlyLoggedInUser',
      value: userObj
    })}
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(LoadAccount);