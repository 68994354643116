import React, {useState, useEffect} from 'react';
import './Account.css';
// import Button from '../../AppButtons/Button/Button'
import PaymentIntentWizard from '../../PaymentIntentWizard/PaymentIntentWizard'
import {getStripeSetupIntentForCreditcardUpdate, setLatestAddedPaymentMethodAsDefault, setGlobalVoiceRedirect, accountSlackIntegrations, deleteAccountSlackIntegration, accountSlackIntegrationMuteToggler} from '../../../Services/User'
import CountryDropdown from '../../Dropdowns/CountryDropdown/CountryDropdown'
import TextInput from '../../InputFields/TextInput/TextInput'
import {translateFriendlyStringToCountryCode} from '../../../Utilities/TextUtils/TextUtilities'
import {scrollToTopOfPage} from '../../../Utilities/DOMutils/DOMutils'
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import { Container } from 'react-bootstrap';
import Spinner from '../../Loaders/Spinner/Spinner'
import {Colors} from '../../../Constants/Style/Colors'
import PhoneInput from 'react-phone-number-input'
import Alert from 'react-bootstrap/Alert'

const Account = props => {
  let [clientSecret, setClientSecret] = useState(null)
  let [loading, setLoading] = useState(false)
  let [slackIntegrations, setSlackIntegrations] = useState([])

  let [country, setCountry] = useState('')
  let [callbackNumber, setCallbackNumber] = useState('')

  useEffect(() => {
    scrollToTopOfPage()
  }, [])

  function fetchClientSecretFromStripe() {
    setLoading(true)
    getStripeSetupIntentForCreditcardUpdate().then(res => res.json())
    .then(obj => {
      if (obj.code === 200) {
        setLoading(false)
        setClientSecret(obj.client_secret)
      } else {
        alert(obj.status)
      }
    })
  }

  function setNewCardAsDefaultPaymentMethod() {
    setLoading(true)
    setLatestAddedPaymentMethodAsDefault().then(res => res.json())
    .then(obj => {
      setLoading(false)
      if (obj.data) {
        alert('Thank you for updating your credit card details! We will bill this card hereafter')
        setClientSecret(null)
      } else {
        alert(obj.status)
      }
    })
  }

  function renderPaymentMethodUi() {
    if (clientSecret) {
      return (
        <PaymentIntentWizard
          updatingPaymentMethod
          paymentIntent={clientSecret}
          onSuccess={setNewCardAsDefaultPaymentMethod}
        />
      )
    }

    if (loading) {
      return (
        <Spinner size="big" color={Colors.secondary}/>
      )
    }
  }
  
  function handleVoiceSetup() {
    setGlobalVoiceRedirect({
      countryCode: country,
      phoneNumber: callbackNumber
    }).then(res => res.json())
    .then(obj => {
      alert(obj.status)
      setCountry('')
      setCallbackNumber('')
    })
  }

  function renderVoiceRedirectionUi() {
    return (
      <div className="m-2">
        <p>We will use this phone number to route calls coming to all your Lenny numbers</p>
        <PhoneInput
          placeholder="Enter phone number"
          onChange={(e) => { setCallbackNumber(e) }}
          value={callbackNumber}
          onCountryChange={(e) => {setCountry(e)}}
        />
        <Button
          disabled={country && callbackNumber ? false : true}
          onClick={handleVoiceSetup}
          className="m-4"
        >
          Save
        </Button>

      </div>
    )
  }

  function fetchExistingSlackIntegrations() {
    setLoading(true)
    accountSlackIntegrations().then(res => res.json())
    .then(obj => {
      setLoading(false)
      if (obj.data) {
        setSlackIntegrations(obj.data)
      } else {
        alert(obj.status)
      }
    })
  }

  function handleSlackIntegrationDeletion(id) {
    if (window.confirm("Are you sure? This Slack channel will not get text messages forwarded to it if removed")) {
      deleteAccountSlackIntegration(id).then(res => res.json())
      .then(obj => {
        fetchExistingSlackIntegrations()
      })
    }
  }

  function toggleSlackIntegrationMute(id) {
    accountSlackIntegrationMuteToggler(id).then(res => res.json())
    .then(obj => {
      fetchExistingSlackIntegrations()
    })
  }

  function renderExistingSlackIntegrations() {
    if (loading) {
      return (
        <Spinner size="big" color={Colors.secondary}/>
      )
    }
    if (slackIntegrations.length > 0) {
      return slackIntegrations.map((integration) => {
        let integrationId = integration.id
        return (
          <Card key={integrationId}>
            <Card.Header>{integration.attributes.team_name}</Card.Header>
            <Card.Body>
              <Card.Title>{integration.attributes.channel_name}</Card.Title>
              <Card.Text>
                Type: {integration.attributes.token_type}
              </Card.Text>
              
              <a className="mr-2 btn btn-secondary btn-sm" href={integration.attributes.config_url} target="_blank">Configure</a>
              {
                integration.attributes.muted ? <Button className="mr-2" size="sm" variant="success" onClick={() => {toggleSlackIntegrationMute(integrationId)}}>unMute</Button>
                : <Button className="mr-2" size="sm" variant="warning" onClick={() => {toggleSlackIntegrationMute(integrationId)}}>Mute</Button>
              }
              <Button size="sm"  variant="danger" onClick={() => {handleSlackIntegrationDeletion(integrationId)}}>Remove</Button>
            </Card.Body>
          </Card>
        )
      })
    } else {
      return (
        <Alert variant="warning">
          No Slack Integrations yet, Click on Add to Slack to get started
        </Alert>
      )
    }

  }

  return (
    <div className="account-wrapper">
      <Container className="mb-4">
        <div className="user-phone-numbers-heading-wrapper">
          <h1 className="user-phone-numbers-heading">
            Account
          </h1>
        </div>
        <Accordion>
          <Card>
            <Card.Header>
              <Accordion.Toggle as={Button} variant="link" eventKey="0" onClick={fetchClientSecretFromStripe}>
                Update Payment Details
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="0">
              <Card.Body style={{backgroundColor: Colors.primary}}>
                {renderPaymentMethodUi()}
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Card.Header>
              <Accordion.Toggle as={Button} variant="link" eventKey="1">
                Call Routing
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="1">
              <Card.Body>
                <div className="account-actions-wrapper">
                  {renderVoiceRedirectionUi()}
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Card.Header>
              <Accordion.Toggle className="mr-4" as={Button} variant="link" eventKey="2" onClick={fetchExistingSlackIntegrations}>
                Slack Integrations
              </Accordion.Toggle>
              <a href={`https://slack.com/oauth/v2/authorize?scope=incoming-webhook,commands,chat:write&client_id=1302365829510.1294354455463&redirect_uri=${window.location.origin}/self/account-settings/slack-integrations/create`}><img alt="add To Slack" height="40" width="139" src="https://platform.slack-edge.com/img/add_to_slack.png" srcset="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x" /></a>
            </Card.Header>
            <Accordion.Collapse eventKey="2">
              <Card.Body>
                <Container fluid className="mt-2">
                  <p>Incoming messages to all your Lenny numbers will be CC'd to these Slack Channels</p>
                  {renderExistingSlackIntegrations()}
                </Container>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      </Container>
    </div>
  )
}

export default Account;