import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.min.css';

// redux setup
import {Provider} from 'react-redux'
import {siteReducer} from './Store/Reducers/SiteReducer'
import {appReducer} from './Store/Reducers/AppReducer'
import { createStore, combineReducers, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk';

// FOR REDUX DEV TOOLS *REMOVE FOR PROD*
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose ; 

const reducers = combineReducers({
  siteReducer: siteReducer,
  appReducer: appReducer,
})

const store = createStore(
  reducers,
  composeEnhancer(applyMiddleware(thunk)),
  )

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
