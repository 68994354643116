import React from 'react';
import './Button.css';
import Spinner from '../../Loaders/Spinner/Spinner'

const Button = props => {
  return (
    <button
      ref={props.useRef}
      type={props.type ? props.type: ''}
      className={`button ${props.name ? props.name : ''}`}
      disabled={props.disabled}
      onClick={props.clicked}>
      <span className="button-title">{props.title}</span>
      <div className="button-spinner"><Spinner size='small' /></div>
    </button>
  )
}

export default Button;