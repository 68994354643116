import {getApiRoot} from '../Constants/ApiRoots'

export const getAvailableNumbersForCountry = (countryId) => {
  return new Promise((resolve, reject) => {
    let endpoint = getApiRoot() + `/api/core/v1/twilio_phone_numbers?country_code=${countryId}`;
    fetch(endpoint, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
    })
      .then(e => {
        if (e.ok) {
          // console.log(e)
          resolve(e);
        } else {
          // console.log(e)
          reject(e);
        }
      })
      .catch(e => console.log("error::", e));
  });
};