import React from 'react';
import './Footer.css';
import {Link} from 'react-router-dom'
import {smoothScrollIntoView} from '../../Utilities/DOMutils/DOMutils'
import {navbarData} from '../../Constants/SiteData/Navbar'
import {Colors} from '../../Constants/Style/Colors'

const Footer = props => {
  function renderLandingLinks() {
    return navbarData.links.list.map((l, i) => {
      if (l.hasPath) {
        return (
          <Link to={l.path}>{l.label}</Link>
        )
      } else {
        return (
          <Link to="/" onClick={(e) => {smoothScrollIntoView(l, props.device)}}>{l.label}</Link>
        )
      }
    })
  }

  return (
    <div className="footer mt-auto py-3" style={{backgroundColor: Colors.secondary}}>
      <div className="footer-wrapper-columns">
        <div className="footer-column-wrapper">
          <div className="footer-column-heading-wrapper">
            <h1>Lenny</h1>
          </div>
          <div className="footer-item-wrapper">
            {renderLandingLinks()}
            <a href="mailto:contact@restarone.com">Support</a>
          </div>
        </div>
        <div className="footer-column-wrapper">
          <div className="footer-column-heading-wrapper">
              <h1>Legal</h1>
          </div>
          <div className="footer-item-wrapper">
            <a href="https://lenny-assets.s3.amazonaws.com/eula.pdf" target="_blank">Terms of Service</a>
          </div>
        </div>
        <div className="footer-column-wrapper">
          <div className="footer-column-heading-wrapper">
            <h1>Social</h1>
            <p>Follow us on social media to find out about latest updates as they come out!</p>
          </div>
          <div className="footer-item-wrapper social-media-wrapper">
            <a href="https://www.instagram.com/lenny.mobi/" target="_blank"><img src="https://www.freepnglogos.com/uploads/instagram-logos-png-images-free-download-2.png"/></a>  
          </div>
        </div>
      </div>
      <div className="footer-bottom-wrapper">
        <div></div>
        <p>© 2020 <a target="_blank" href="https://www.restarone.com/">Restarone Solutions Inc.</a> All rights reserved.</p>
      </div>
    </div>
  )
}

export default Footer;