import React from 'react';
import './AppStoresGroup.css';
import appleAppstoreIcon from '../../../Assets/LandingPage/shared/download-on-the-appstore.svg'

const AppStoresGroup = props => {
  return (
    <div className="appstores-group">
      <a target="_blank" href='https://play.google.com/store/apps/details?id=com.restarone.lenny&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
        <img alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/>
      </a>
      <a target="_blank" href="https://apps.apple.com/us/app/id1528051136">
        <img
          src={appleAppstoreIcon}
          alt='Get it on Apple App Store'
        />
      </a>
    </div>
  )
}

export default AppStoresGroup;