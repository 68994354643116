import React, {useState} from 'react';
import './AccountReconciliationWizard.css';
import PaymentIntentWizard from '../PaymentIntentWizard/PaymentIntentWizard'
import {updateUser, deleteUserAccount} from '../../Services/User'
import SignupFee from '../SignupFee/SignupFee'
import TermsAndConditions from '../TermsAndConditions/TermsAndConditions'
import {clearDataAndSignOut} from '../../Utilities/LocalStorage/UserAuthStorage'
import Container from 'react-bootstrap/Container'
import Alert from 'react-bootstrap/Alert'
import { LinkContainer } from 'react-router-bootstrap'

function AccountReconciliationWizard(props) {  
  let user = props.user

  let [disableSignupFeeAgreeButton, setSignupFeeAgreeButton] = useState(false)
  let [disableSignupFeeDisagreeButton, setSignupFeeDisagreeButton] = useState(false)

  let [signupFeeShowAgreeLoader, setSignupFeeShowAgreeLoader] = useState(false)

  let [disableTermsAgreeButton, setDisableTermsAgreeButton] = useState(false)
  let [showTermsLoader, setShowTermsLoader] = useState(false)

  const hasNotAddedCreditCardYet = () => {
    if (!user.paymentMethodAdded) {
     return true
    }
  }

  const hasNotAcceptedTermsYet = () => {
    if (!user.agreedToTerms) {
     return true
    }
  }

  const hasNotPaidSignupFee = () => {
    if (!user.signupFeePaid) {
     return true
    }
  }

  const creditCardIsProblematic = () => {
    if (user.paymentMethodAdded && !user.paymentMethodValid) {
      return true
    }
  }

  const handlePaymentIntentSuccess = () => {
    updateUser('credit-card-added', user.userId).then(res => res.json())
    .then(obj => {
      if (obj.data && obj.data.type === 'user') {
        props.refreshUserData(user.userId)
      } else {
        alert(obj.status)
      }
    })
  }

  const handleSignupFeeSubmission = () => {
    setSignupFeeAgreeButton(true)
    setSignupFeeDisagreeButton(true)
    setSignupFeeShowAgreeLoader(true)
    updateUser('signup-fee-paid', user.userId).then(res => res.json())
    .then(obj => {
      if (obj.data && obj.data.type === 'user') {
        props.refreshUserData(user.userId)
      } else {
        alert(obj.status)
      }
    })
  }

  const handleTermsAccept = () => {
    setDisableTermsAgreeButton(true)
    setShowTermsLoader(true)
    updateUser('agree-to-terms', user.userId).then(res => res.json())
    .then(obj => {
      if (obj.data && obj.data.type === 'user') {
        props.refreshUserData(user.userId)
      } else {
        alert(obj.status)
      }
    })
  }

  const handleAccountRemoval = () => {
    deleteUserAccount().then(res => res.json())
    .then(obj => {
      alert(obj.status)
      if (obj.code === 200) {
        clearDataAndSignOut()
      }
    })
  }

  const handleSignupFeeRejection = () => {
    if (window.confirm("Are you sure? This cannot be undone")) {
      handleAccountRemoval()
    }
  }

  const renderReconciliationContext = () => {
    if (hasNotAcceptedTermsYet()) {
      return (
        <TermsAndConditions
          onAgree={handleTermsAccept}
          onDisagree={handleSignupFeeRejection}
          agreeDisabled={disableTermsAgreeButton} 
          disagreeDisabled={disableTermsAgreeButton}
          showAgreeLoader={showTermsLoader}
        />
      )
    }

    if (hasNotAddedCreditCardYet()) {
      return <PaymentIntentWizard onSuccess={handlePaymentIntentSuccess} paymentIntent={user.stripePaymentIntentId}/>
    }

    if (hasNotAddedCreditCardYet()) {
      return <PaymentIntentWizard onSuccess={handlePaymentIntentSuccess} paymentIntent={user.stripePaymentIntentId}/>
    }

    if (hasNotPaidSignupFee()) {
      return <SignupFee showAgreeLoader={signupFeeShowAgreeLoader} agreeDisabled={disableSignupFeeAgreeButton} disagreeDisabled={disableSignupFeeDisagreeButton} onAgree={handleSignupFeeSubmission} onDisagree={handleSignupFeeRejection}/>
    }

    if (creditCardIsProblematic()) {
      return (
        <Container className="mt-4">
          <Alert variant={'danger'}>
            Your current payment method is invalid.
            <LinkContainer to="/self/account-settings">
              <Alert.Link>&nbsp;Click here</Alert.Link> 
            </LinkContainer>
            &nbsp;to update it and resume service.
          </Alert>
        </Container>
      )
    }
  }

  return (
    <div className="account-reconciliation-wizard-wrapper">
     {renderReconciliationContext()}
    </div>
  );
}

export default AccountReconciliationWizard;
