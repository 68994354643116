import React, {useState, useEffect} from 'react';
import './CostEstimation.css';
import CountryDropdown from '../Dropdowns/CountryDropdown/CountryDropdown'
import Button from '../AppButtons/Button/Button'
import {scrollToTopOfPage} from '../../Utilities/DOMutils/DOMutils'
import {getPhoneCostsForCountry} from '../../Services/Public'
import CostEstimationResult from '../CostEstimationResult/CostEstimationResult'

const CostEstimation = props => {
  let [selectedCountryCode, setSelectedCountryCode] = useState(null)
  let [renderCost, setRenderCost] = useState(false)
  let [rentalCostObj, setRentalCostObj] = useState(null)
  let [smsCostObj, setSmsCostObj] = useState(null)
  let [voiceCost, setVoiceCost] = useState(null)
  let [loading, setLoading] = useState(false)

  useEffect(() => {
    scrollToTopOfPage()
  }, [])

  const fetchPhoneCosts = () => {
    setLoading(true)
    getPhoneCostsForCountry(selectedCountryCode.value, 'rental_cost').then(res => res.json())
    .then(obj => {
      if (obj.currency) {
        setRentalCostObj(obj)
        getPhoneCostsForCountry(selectedCountryCode.value, 'sms_costs').then(res => res.json())
        .then(obj => {
          if (obj.currency) {
            setSmsCostObj(obj)
            getPhoneCostsForCountry(selectedCountryCode.value, 'voice_costs').then(res => res.json())
            .then(obj => {
              if (obj.voice_pricing) {
                setLoading(false)
                setRenderCost(true)
                setVoiceCost(obj.voice_pricing)
              } else {
                alert('an error occured, please try again later')
                setLoading(false)
              }
              
            })
          } else {
            alert('an error occured, please try again later')
            setLoading(false)
          }
        })
      } else {
        alert('an error occured, please try again later')
        setLoading(false)
      }
    })
  }

  const renderButtonContext = () => {
    if (renderCost) { 
      return (
        <Button clicked={() => { setRenderCost(false) }} title="Back" name="next-button"/>
      )
    } else {
      return (
        <Button clicked={fetchPhoneCosts} title="Next" disabled={ selectedCountryCode !== null ? false : true } name={`next-button ${loading ? 'loading' : ''}`}/>
      )
    }
  }

  const renderCountrySelectionContext = () => {
    if (!renderCost) {
      return (
        <div className="cost-estimation-country-select-wrapper">
          <CountryDropdown device={props.device} onSelect={(e) => { setSelectedCountryCode(e) }}/>
        </div>
      )
    } else {
      return (
        <CostEstimationResult country={selectedCountryCode.label} rentalCost={rentalCostObj} smsCost={smsCostObj} voiceCost={voiceCost}/>
      )
    }
  }

  return (
    <div className="cost-estimation-wrapper">
      <div className="cost-estimation-heading-wrapper">
        <h1 className="cost-estimation-heading">Cost Estimation</h1>
        <h2 className="cost-estimation-subheading">Specify the country in which you would like to buy a number and we will calculate the costs for you</h2>
      </div>
      <div className="cost-estimation-country-wrapper">
        {renderCountrySelectionContext()}
      </div>
      <div className="cost-estimation-button-wrapper">
        {renderButtonContext()}
      </div>
    </div>
  )
}

export default CostEstimation;