import React from 'react';
import './TermsAndConditions.css';
import Button from '../AppButtons/Button/Button'

const TermsAndConditions = props => {
  const appendLoaderClass = () => {
    if (props.showAgreeLoader) {
      return 'loading'
    }
  }

  return (
    <div className="payment-intent-wizard-info-wrapper">
      <div>
        <a className="payment-intent-wizard-info " href="https://lenny-assets.s3.amazonaws.com/eula.pdf" target="_blank">Click to view End User License Agreement</a>
      </div>
      <div className="signupfee-info-controls">
        <Button disabled={props.disagreeDisabled} title="Disagree" name={`signupfee-disagree-button ${appendLoaderClass()}`} clicked={props.onDisagree}/>
        <Button disabled={props.agreeDisabled} title="Reviewed and Agree" name={`signupfee-agree-button ${appendLoaderClass()}`} clicked={props.onAgree}/>
      </div>
    </div>  
  )
}

export default TermsAndConditions;