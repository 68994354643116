import {initialState} from '../InitialStates/App/InitialState'
import {setCurrentLoggedInUserInfo, unSetCurrentLoggedInUserInfo} from '../ReducerHelpers/App/UserReducerActions'

export const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'setCurrentlyLoggedInUser': 
      return setCurrentLoggedInUserInfo(state, action)
    case 'unsetCurrentlyLoggedInUser': 
      return unSetCurrentLoggedInUserInfo(state)
    default: 
    return state
  }
}

export default appReducer