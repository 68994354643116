import React from 'react';
import './PhoneNumberBuySuccess.css';
import ButtonAnchor from '../AppButtons/ButtonAnchor/ButtonAnchor'

const PhoneNumberBuySuccess = props => {
  return (
    <div className="phone-number-buy-success-wrapper">
      <div className="phone-number-buy-success-info-wrapper">
        <p className="phone-number-buy-success-info">{props.purchasedNumber.number} was successfully bought. We will automatically bill you ${props.purchasedNumber.quoted_price} every month.</p>
        <p className="phone-number-buy-success-info">To use this new number without service interruptions, please ensure that you load your account with credits.</p>
      </div>
      <div className="phone-number-buy-success-controls">
        <ButtonAnchor url="/self/phone-numbers" label="My Phone Numbers"/>
        <ButtonAnchor url="/self/load-account" label="Load My Account"/>
      </div>
    </div>
  )
}

export default PhoneNumberBuySuccess;