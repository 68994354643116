import React, {useState, useEffect} from 'react';
import './NewConversation.css';
import TextAreaInput from '../InputFields/TextAreaInput/TextAreaInput'
import Button from '../AppButtons/Button/Button'
import {expandTextArea} from '../../Utilities/DOMutils/DOMutils'
import {phoneNumberHasDialingCodeAttached} from '../../Utilities/TextUtils/TextUtilities'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'

const NewConversation = props => {
  let [recipientPhoneNumber, setRecipientPhoneNumber] = useState('')
  let [messageBody, setMessageBody] = useState('')

  function preflightCheck(e) {
    if (phoneNumberHasDialingCodeAttached(recipientPhoneNumber)) {
      props.onSend(e, {
        messageBody: messageBody,
        recipientNumber: recipientPhoneNumber
      })
    } else {
      alert('Phone number is invalid, please only the number and the country dialing code. eg +2123456789')
    }
  }

  function computeContextualCss(element) {
    if (props.fullSize) {
      switch(element) {
        case 'wrapper':
          return 'new-conversation-border'
      }
    }
    return ''
  }
  
  return (
    <form className={`new-conversation-wrapper ${computeContextualCss('wrapper')}`}>
      <div className="new-conversation-phone-input">
        <PhoneInput
          placeholder="Enter phone number"
          onChange={(e) => { setRecipientPhoneNumber(e) }}
          value={recipientPhoneNumber}
        />
      </div>
      <div className="new-conversation-message-input-wrapper">
        <div>
          <TextAreaInput
            placeholder="Enter message"
            onChange={(e) => { setMessageBody(e.target.value) ; expandTextArea(e.target) }}
            value={messageBody}
          />
        </div>
        <div>
          <Button
            title="Send"
            name={`next-button ${props.loading ? 'loading' : ''}`}
            disabled={recipientPhoneNumber && messageBody ? false : true}
            clicked={
              (e) => {
                preflightCheck(e)
              }
            }
          />
        </div>
      </div>
  </form>
  )
}

export default NewConversation;