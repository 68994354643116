import React from 'react';
import ErrorModal from '../AppModals/ErrorModal/ErrorModal'

const ErrorBox = props => {
  let {userMeta} = props

  function renderError() {
    let userAuth = userMeta.authentication
    let userCredits = userMeta.credits
    
    if (userAuth.signupFeePaid) {
      // only show error if the user has paid the sign up fee (these errors are for accounts that are set up)
      if (userAuth.isProblematic && userAuth.paymentMethodAdded) {
        if (userCredits.amount < 1) {
          return (
            <ErrorModal
              message="Please reload your account with credits to use Lenny"
              correctionLabel="Reload Account"
              correctionPath='/self/load-account'
            />
          )
        }
      }

      if (!userAuth.paymentMethodAdded) {
        return (
          <ErrorModal
            message="No payment method added, please add a credit card"
            correctionLabel="Add Payment Method"
            correctionPath='/self/account-settings'
          />
        )
      }

      if (!userAuth.paymentMethodValid) {
        return (
          <ErrorModal
            message="Credit card is expired or otherwise not valid. Please update it"
            correctionLabel="Update Payment Method"
            correctionPath='/self/account-settings'
          />
        )
      }
    }
    return null
  }

  return (
    renderError()
  )
}

export default ErrorBox;