import React, {useState} from 'react';
import './CostEstimationResult.css';
import TextInput from '../InputFields/TextInput/TextInput'
import Button from '../AppButtons/Button/Button'
import {autoPluralize} from '../../Utilities/TextUtils/TextUtilities'
import Card from 'react-bootstrap/Card'
import ListGroup from 'react-bootstrap/ListGroup'
import ListGroupItem from 'react-bootstrap/ListGroupItem'
import Badge from 'react-bootstrap/Badge'
import InputGroup from 'react-bootstrap/InputGroup'
import FormControl from 'react-bootstrap/FormControl'

const CostEstimationResult = props => {
  let { country, rentalCost, smsCost } = props
  let smsInboundPrice = parseFloat(smsCost.inbound_sms_price_for_local_number)
  let smsOutboundPrice = parseFloat(smsCost.average_outbound_sms_price_for_local_number)

  let voiceInboundCost = props.voiceCost? props.voiceCost.inbound_cost.map((c) => {
    return {...c, current_price: parseFloat(c.current_price)}
  }).sort((a, b) => a.current_price - b.current_price) : null
  let voiceOutboundCost = props.voiceCost ? props.voiceCost.outbound_cost.map((c) => {
    return {...c, current_price: parseFloat(c.current_price)}
  }).sort((a, b) => a.current_price - b.current_price) : null

  let numberRentalCost = rentalCost.pricing.find((c) => {
    if (c.number_type === 'local' || c.number_type === 'mobile') {
      return c
    }
  })

  let [showEstimation, setShowEstimation] = useState(false)
  let [textCount, setTextCount] = useState('500')
  let [minuteCount, setMinuteCount] = useState('250')
  let [numberCount, setNumberCount] = useState('2')

  const renderRentalCost = () => {
    return `$${numberRentalCost.current_price}`
  }

  const renderMessagingCost = () => {
    if (!Number.isNaN(smsInboundPrice) && !Number.isNaN(smsOutboundPrice)) {
      return (
        [
          `${smsOutboundPrice.toFixed(4)} USD`,
          `${smsInboundPrice.toFixed(4)} USD`,
        ]
        
      )
    }

    if (!Number.isNaN(smsOutboundPrice)) {
      return (
        [
          `${smsOutboundPrice.toFixed(4)} USD`,
          `${smsOutboundPrice.toFixed(4)} USD`,
        ]
        
      )
    }

    if (!Number.isNaN(smsInboundPrice)) {
      return (
        [
          `${smsInboundPrice.toFixed(4)} USD`,
          `${smsInboundPrice.toFixed(4)} USD`,
        ]
        
      )
    }
  }

  const renderVoiceCost = () => {
    if (props.voiceCost) {
      // convert to floats and sort by cheapest first
      return ([
        `$${voiceInboundCost[0].current_price} ${props.voiceCost.price_unit}`,
        `$${voiceOutboundCost[0].current_price} ${props.voiceCost.price_unit}`
      ])
    }
  }

  function renderDefaultEstimation() {

    return (
      <Card>
        <Card.Body>
          <Card.Title>
            <Badge pill variant="primary">
              {renderRentalCost()}
            </Badge>
            &nbsp;per month, per number
          </Card.Title>
        </Card.Body>
        <ListGroup className="list-group-flush text-left">
          <ListGroupItem>
            incoming calls&nbsp;
            <Badge pill variant="secondary">
              {renderVoiceCost() ? renderVoiceCost()[0] : ''} 
            </Badge>
            &nbsp;per minute
          </ListGroupItem>
          <ListGroupItem>
            outgoing calls&nbsp;
            <Badge pill variant="secondary">
              {renderVoiceCost() ? renderVoiceCost()[1]: ''} 
            </Badge>
            &nbsp;per minute
          </ListGroupItem>
          <ListGroupItem>
            outgoing text&nbsp;
            <Badge pill variant="info">
              {renderMessagingCost()[0]} 
            </Badge>
            &nbsp;per message
          </ListGroupItem>
          <ListGroupItem>
            incoming text&nbsp;
            <Badge pill variant="info">
              {renderMessagingCost()[1]} 
            </Badge>
            &nbsp;per message
          </ListGroupItem>
        </ListGroup>
      </Card>
    )
  }

  function renderEstimationContext() {
    if (!showEstimation) {

      return (
        <Card>
          <Card.Body>
            <Card.Title>
              Estimated monthly cost
            </Card.Title>
          </Card.Body>
          <Card.Body className="mt-0 pt-0">
            <InputGroup size="sm" className="mb-3">
              <FormControl 
                pattern='[0-9]*'
                placeholder="600"
                onChange={(e) => {setTextCount(e.target.value.replace(/\D/,''))}}
                value={textCount}
              />
              <InputGroup.Prepend>
                <InputGroup.Text className="bg-info">Text Messages</InputGroup.Text>
              </InputGroup.Prepend>
            </InputGroup>
            <InputGroup size="sm" className="mb-3">
              <FormControl 
                pattern='[0-9]*'
                placeholder="500"
                onChange={(e) => {setMinuteCount(e.target.value.replace(/\D/,''))}}
                value={minuteCount}
              />
              <InputGroup.Prepend>
                <InputGroup.Text className="bg-info">Talk Time Minutes</InputGroup.Text>
              </InputGroup.Prepend>
            </InputGroup>
            <InputGroup size="sm" className="mb-3">
              <FormControl 
                pattern='[0-9]*'
                placeholder="5"
                onChange={(e) => {setNumberCount(e.target.value.replace(/\D/,''))}}
                value={numberCount}
              />
              <InputGroup.Prepend>
                <InputGroup.Text className="bg-info">Phone Numbers</InputGroup.Text>
              </InputGroup.Prepend>
            </InputGroup>
            <Button 
              title="Get Estimate"
              name="next-button"
              clicked={() => {setShowEstimation(true)}}
            />
          </Card.Body>
        </Card>
      )
    } else {
      return renderEstimate()
    }
  }

  function renderEstimate() {
    let phoneNumbersCount = numberCount ? numberCount : 0
    let textMessageCount = textCount ? textCount : 0
    let talkMinutes = minuteCount ? minuteCount : 0

    let textingPrice = !Number.isNaN(smsInboundPrice) ? smsInboundPrice : smsOutboundPrice
    let rentalCostPerNumber = parseFloat(numberRentalCost.current_price)
    let voiceCostAverage = (voiceInboundCost[0].current_price + voiceOutboundCost[0].current_price) / 2
    let totalRentalCost = phoneNumbersCount * rentalCostPerNumber 
    let totalUsageCost = (textMessageCount * textingPrice) + (talkMinutes * voiceCostAverage)
    let totalCost = (totalRentalCost) + totalUsageCost

    return (
      <Card>
        <Card.Body>
          <Card.Title>Monthly cost</Card.Title>
          <Card.Text className="mb-4">
            For {numberCount} {autoPluralize(numberCount, 'number')}, the monthly charge is: ${totalRentalCost.toFixed(2)}
          </Card.Text>
          <Card.Text className="mb-4">
            For {textCount} {autoPluralize(textCount, 'text')} and {minuteCount} {autoPluralize(minuteCount, 'minute')} It will cost: ${totalUsageCost.toFixed(2)}
          </Card.Text>
          <Card.Subtitle className="mb-2 text-muted">Total cost per month: <strong>${totalCost.toFixed(2)}</strong></Card.Subtitle>
          <Button 
              title="Close"
              name="next-button"
              clicked={() => {setShowEstimation(false)}}
          />
        </Card.Body>
      </Card>
    )
  }

  return (
    <div className="cost-estimation-result-wrapper">
      <div className="cost-estimation-result-country-wrapper">
        <h1 className="cost-estimation-result-country">{props.country}</h1>
      </div>
      <div className="cost-estimation-result-content">
        <div className="cost-estimation-breakdown-wrapper">
          {renderDefaultEstimation()}
        </div>
        <div className='cost-estimation-perspective-wrapper'>
          {renderEstimationContext()}
        </div>
      </div>
    </div>  
  )
}

export default CostEstimationResult;