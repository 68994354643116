import React from 'react';
import './ConversationTitle.css';
import TextInput from '../InputFields/TextInput/TextInput'
// import Button from '../AppButtons/Button/Button'
import InputGroup from 'react-bootstrap/InputGroup'
import FormControl from 'react-bootstrap/FormControl'
import Button from 'react-bootstrap/Button'

const ConversationTitle = props => {

  return (
    <InputGroup className="mb-3" onClick={(e) => {e.stopPropagation()}}>
      <FormControl
        placeholder="Conversation Title"
        aria-label="Recipient's username"
        aria-describedby="basic-addon2"
        value={props.title}
        onChange={props.onInput}
      />
      <InputGroup.Append>
        <Button onClick={props.onClick} variant="outline-secondary">Save</Button>
      </InputGroup.Append>
    </InputGroup>
  )
}

export default ConversationTitle;