import React from 'react';
import './PhoneNumberListItem.css';
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import { formatPhoneNumberIntl } from 'react-phone-number-input'

const PhoneNumberListItem = props => {

  const usageCost = () => {
    let pricing = props.number.sms_pricing
    return {
      outboundSmsPrice: pricing.average_outbound_cost ? parseFloat(pricing.average_outbound_cost).toFixed(3) : 'unknown',
      inboundSmsPrice: pricing.inbound_cost && pricing.inbound_cost.current_price ? parseFloat(pricing.inbound_cost.current_price).toFixed(3) : 'unknown'
    }
  }
  
  return (
    <Card className="m-2">
      <Card.Header as="h5">{formatPhoneNumberIntl(props.number.friendly_name) ? formatPhoneNumberIntl(props.number.friendly_name) : props.number.friendly_name}</Card.Header>
      <Card.Body>
        <Card.Title>${props.number.monthly_cost} monthly</Card.Title>
        <Card.Text>
          ${usageCost().outboundSmsPrice} per outgoing message | ${usageCost().inboundSmsPrice} per incoming message
        </Card.Text>
        <Button onClick={() => {props.onClick(props.number)}} variant="primary">Select</Button>
      </Card.Body>
    </Card>
  )
}

export default PhoneNumberListItem;