import React from 'react';
import './OwnedPhoneNumberListItem.css';
import {Link} from 'react-router-dom'
import {translateCountryCodeToFriendlyString} from '../../Utilities/TextUtils/TextUtilities'
import DropdownMenu from '../Dropdowns/DropdownMenu/DropdownMenu'
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formatPhoneNumberIntl } from 'react-phone-number-input'
import Card from 'react-bootstrap/Card'
import Dropdown from 'react-bootstrap/Dropdown'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Badge from 'react-bootstrap/Badge'
import { LinkContainer } from 'react-router-bootstrap'
import {Colors} from '../../Constants/Style/Colors'
import Button from 'react-bootstrap/Button'
import {autoPluralize} from '../../Utilities/TextUtils/TextUtilities'

const OwnedPhoneNumberListItem = props => {
  let {number} = props

  const listOptions = [
    {
      label: 'Remove from account',
      callback: () => { props.onDeprovision(number.id) }
    },
  ]

  function renderOptions() {
    return listOptions.map((opt, i) => {
      return (
        <Dropdown.Item onClick={opt.callback}>{opt.label}</Dropdown.Item>
      )
    })
  }

  function renderActiveStatus() {
    if (number.attributes.active) {
      return (
        <Badge className="ml-0" variant="success" pill>Active</Badge>
      )
    } else {
      return (
        <Badge className="ml-0" variant="danger" pill>Disabled</Badge>
      )
    }
  }

  function renderUnreadConversationStatus() {
    let unreadConversations = parseInt(number.attributes.unread_conversation_count)
    if (unreadConversations > 0) {
      return (
        <LinkContainer to={`/self/phone-numbers/${number.id}/conversations`}>
          <Card.Link>
            <Badge className="ml-2" variant="warning" pill>
              {unreadConversations} unread {autoPluralize(unreadConversations, 'convo')}
            </Badge>
          </Card.Link>
        </LinkContainer>
      )
    }
  }

  return (
    <Card style={{ width: '18rem', margin: `10px ${props.device === 'mobile' ? 'auto 0' : '10px'}` }}>
      <Card.Body>
        <Row>
          <Col
            className="pr-0 mr-0 text-left"
            lg={8}
            md={8}
            sm={7}
            xs={8}
          >
            <LinkContainer style={{color: Colors.primary}} to={`/self/phone-numbers/${number.id}/conversations`}>
              <Card.Link>
                <Card.Title>{formatPhoneNumberIntl(number.attributes.number)}</Card.Title>
              </Card.Link>
            </LinkContainer>
          </Col>
          <Col 
            className="pr-0 mr-0 text-right"
            lg={4}
            md={4}
            sm={3}
            xs={4}
          >
            <Dropdown className="pr-0 mr-0 lenny-primary-bg lenny-primary">
              <Dropdown.Toggle style={{backgroundColor: Colors.primary}} variant="success" size="sm" >
                Options
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {renderOptions()}
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>
        <Card.Subtitle className="mb-2 text-muted">{translateCountryCodeToFriendlyString(number.attributes.country_code)}</Card.Subtitle>
        <Card.Text>
          {`${number.attributes.outgoing_message_cost} per outgoing message`}
        </Card.Text>
        <Card.Text>
        {`${number.attributes.incoming_message_cost} per incoming message`}
        </Card.Text>
        {renderActiveStatus()}
        {renderUnreadConversationStatus()}
      </Card.Body>
    </Card>
  )
}

export default OwnedPhoneNumberListItem;