import React, {useState, useEffect} from 'react';
import './ConversationListItem.css';
import Conversation from '../Conversation/Conversation'
import {formatTimestamp} from '../../Utilities/TimeUtils/TimeUtilities'
import ConversationTitle from '../ConversationTitle/ConversationTitle'
import Cable from 'actioncable'
import {getWebsocketRoot} from '../../Constants/ApiRoots'
import {getAuthenticationDataFromLocalStorage} from '../../Utilities/LocalStorage/UserAuthStorage'
import { formatPhoneNumber } from 'react-phone-number-input'
import Card from 'react-bootstrap/Card'
import {Colors} from '../../Constants/Style/Colors'
import Button from 'react-bootstrap/Button'
import Badge from 'react-bootstrap/Badge'

const ConversationListItem = props => {
  let {phoneNumberId, conversation} = props
  let messages = conversation.attributes.messages.data
  let user = getAuthenticationDataFromLocalStorage()
  let recipientNumber = conversation.attributes.recipient_number
  let webSocketRoot = getWebsocketRoot(user.user_id)

  let [expanded, setExpanded] = useState(false)
  let [title, setTitle] = useState(conversation.attributes.title)
  let [titleEditMode, setTitleEditMode] = useState(false)

  useEffect(() => {
    if (user) {
      initializeSocket()
    }
  }, [])

  function initializeSocket() {
    let cable = Cable.createConsumer(webSocketRoot);
    cable.subscriptions.create({
      channel: 'MessagesChannel',
      conversationId: conversation.id,
    }, 
    {
      connected: () => {
      },
      received: (res) => {
        props.updateConversation(conversation.id)
      },
      disconnected: () => {
      }  
    })
  }

  const listOptions = [
    {
      label: 'Call',
      callback: () => { props.onCall({recipientNumber: recipientNumber}) }
    },
    {
      label: 'Edit Title',
      callback: () => { setTitleEditMode(true) }
    },
    {
      label: 'Delete',
      callback: () => { props.onDelete(conversation.id) }
    },
  ]

  function renderExpandedConversation() {
    if (expanded && !props.splitViewContext) {
      return (
        <Conversation
          phoneNumberId={phoneNumberId}
          conversation={conversation}
          refreshIndex={props.updateMessageIndex}
          device={props.device}
          orientation={props.orientation}
          updateSelf={props.updateConversation}
        />
      )
    }
  }

  function renderTitleEditContext() {
    if (titleEditMode) {
      return (
        <ConversationTitle
          title={title}
          onInput={(e) => {setTitle(e.target.value)}}
          onClick={() => {props.onTitleUpdate(phoneNumberId, conversation.id, title) ; setTitleEditMode(false)}}
        />
      )
    } else {
      return (
        <Card.Title>
          {conversation.attributes.unread ? <Badge pill className="mr-1" variant="primary">Unread</Badge>: ''}
          {conversation.attributes.title} 
        </Card.Title>
      )
    }
  }

  function handleClick() {
    setExpanded(!expanded)
    setTitleEditMode(false)
    if (props.splitViewContext) {
      props.onClick(conversation)
    }
  }

  function callHandler() {
    if (window.confirm(`Are you sure you want to call ${formatPhoneNumber(recipientNumber)}?`)) {
      props.onCall({recipientNumber: recipientNumber})
    }
  }

  return (
    <Card 
      onClick={handleClick} 
      className={`p-0 ${props.currentlySelected ? 'border border-primary' : ''}`}
      style={{ 
        width: '100%',  
        margin: '0 0 auto', 
        cursor: 'pointer',
      }}
    >
      <Card.Body>
        {renderTitleEditContext()}
        <Card.Subtitle style={{color: props.currentlySelected ? Colors.secondary: ''}} className="mb-2 text-muted">To: {formatPhoneNumber(recipientNumber)}</Card.Subtitle>
        <Card.Text>
          {messages[messages.length - 1].attributes.body}
        </Card.Text>
        <Card.Body className="m-0 p-0 d-flex flex-wrap align-items-start">
          <Card.Link
            className="m-1" 
            onClick={(e) => { callHandler(); e.stopPropagation()}}
          >
            <Button style={{color: 'black'}} variant="outline-warning">Call</Button>
          </Card.Link>
          <Card.Link 
            className="m-1"
            onClick={(e) => { setTitleEditMode(true); e.stopPropagation() }}
          >
            <Button variant="outline-secondary">Edit Title</Button>
          </Card.Link>
          <Card.Link 
            className="m-1"
            onClick={(e) => { props.onDelete(conversation.id); e.stopPropagation() }}
          >
            <Button variant="outline-danger">Delete</Button>
          </Card.Link>
        </Card.Body>
        <p className="m-1">{conversation.attributes.last_message_recieved ? formatTimestamp(conversation.attributes.last_message_recieved) : formatTimestamp(conversation.attributes.created_at)}</p>
      </Card.Body>
      <Card.Body className={`${props.device === 'mobile' ? 'm-1': ''} mt-0 pt-0`}>
        {renderExpandedConversation()}
      </Card.Body>
    </Card>
  )
}

export default ConversationListItem;