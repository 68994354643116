import React from 'react';
import './MobileNavbar.css';
import {Link} from 'react-router-dom'
import BrandLogo from '../../../Assets/BrandLogos/lenny-logo.svg'

function MobileNavbar(props) {
  const renderHamburger = () => {
    if (!props.overlayOpen) {
      return (
        <div className={`mobile-navbar-hamburger-wrapper`} onClick={props.onClick}>
          <div className={`mobile-navbar-hamburger`}>
            <div className={`mobile-navbar-hamburger-layer`}></div>
            <div className={`mobile-navbar-hamburger-layer`}></div>
            <div className={`mobile-navbar-hamburger-layer`}></div>
          </div>
        </div>
      )
    }
  }

  return (
    <div className={`mobile-navbar-wrapper animated fadeInDown`}>
      <div className={`mobile-navbar-content-wrapper`}>
        <div className={`mobile-navbar-brand-wrapper`}>
          <Link to={'/'} className={`mobile-navbar-brand-link`}>
            <img src={BrandLogo} className={`mobile-navbar-brand-logo`}></img>
          </Link>
        </div>
        {renderHamburger()}
      </div>
    </div>
  );
}

export default MobileNavbar;
