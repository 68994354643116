import React from 'react';
import './PrivacyCommitment.css';
import bullet from '../../../Assets/LandingPage/shared/bullet-check.svg'
import lock from '../../../Assets/LandingPage/shared/lock.svg'

const PrivacyCommitment = props => {
  return (
    <div className="privacy-commitment-wrapper">
      <h1 className="privacy-commitment-heading">Security and privacy is not optional, which is why we,</h1>
        <div className="privacy-commitments">
          <div className="commitment-wrapper">
            <img src={bullet} className="commitment-bullet"/>
            <p className="commitment-body">Keep phone number ownership private</p>
          </div>  
          <div className="commitment-wrapper">
            <img src={bullet} className="commitment-bullet"/>
            <p className="commitment-body">Encrypt your text messages and phone calls</p>
          </div>  
          <div className="commitment-wrapper">
            <img src={bullet} className="commitment-bullet"/>
            <p className="commitment-body">Use a Google Login</p>
          </div>  
          <div className="commitment-wrapper">
            <img src={bullet} className="commitment-bullet"/>
            <p className="commitment-body">Use SSL and signed cookies for our website</p>
          </div>  
        </div>
      <img className="privacy-commitment-lock" src={lock}/>
      <div className="privacy-commitment-footer-wrapper">
        <p className="privacy-commitment-footer">We sweat these details, so you can be confident that your communications are safe from prying eyes</p>
      </div>
    </div>
  )
}

export default PrivacyCommitment;