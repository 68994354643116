import React, {useState, useLayoutEffect} from 'react';
import './UserPhoneNumbers.css';
import {listOwnedPhoneNumbers, updateOwnedPhoneNumber} from '../../Services/User'
import ButtonAnchor from '../AppButtons/ButtonAnchor/ButtonAnchor'
import OwnedPhoneNumberListItem from '../OwnedPhoneNumberListItem/OwnedPhoneNumberListItem'
import {scrollToTopOfPage} from '../../Utilities/DOMutils/DOMutils'
import Container from 'react-bootstrap/Container'
import Spinner from '../../Components/Loaders/Spinner/Spinner'
import Alert from 'react-bootstrap/Alert'

const UserPhoneNumbers = props => {
  let [numbers, setNumbers] = useState([])
  let [loadingNumbers, setLoadingNumbers] = useState(false)

  useLayoutEffect(() => {
    fetchPhoneNumbers()
    scrollToTopOfPage()
  }, [])
  
  function fetchPhoneNumbers() {
    setLoadingNumbers(true)
    listOwnedPhoneNumbers().then(res => res.json())
    .then(obj => {
      setLoadingNumbers(false)
      if (obj.data) {
        setNumbers(obj.data)
      } else {
        alert(obj.status)
      }
    })
  }

  function renderNumbers() {
    if (!loadingNumbers) {
      if (numbers.length > 0) {
        return numbers.map((number, i) => {
          return (
            <OwnedPhoneNumberListItem
              number={number}
              key={i}
              onDeprovision={handleDeprovision}
              device={props.device}
            />
          )
        })
      } else {
        return (
          <Alert variant='warning' className="text-center m-2">
            No Phone numbers, yet.
          </Alert>
        )
      }
    } else {
      return <Spinner size="big"/>
    }
  }

  function handleDeprovision(phoneNumberId) {
    if (window.confirm('Are you sure? This cannot be undone')) {
      updateOwnedPhoneNumber(phoneNumberId, {actionType: 'deprovision-number'}).then(res => res.json())
      .then(obj => {
        if (obj.code === 200) {
          alert('Phone number has been removed from this account. You cannot use it for messaging and you will not be billed monthly for it. Existing conversations have been archived')
          fetchPhoneNumbers()
        } else {
          alert('Phone number could not be deprovisioned due to error')
        }
      })
    }
  }

  return (
    <div className="user-phone-numbers-wrapper">
      <div className="user-phone-numbers-heading-wrapper">
        <h1 className="user-phone-numbers-heading">
          My Numbers
        </h1>
      </div>
      <div className="user-phone-numbers-buy-wrapper">
          <ButtonAnchor 
            url="/phone-numbers/buy"
            label="Get a number"
          />
      </div>
      <Container 
        className="
          pb-4 
          d-flex 
          flex-wrap 
          align-items-start
        " 
        >
        {renderNumbers()}
      </Container>
    </div>
  )
}

export default UserPhoneNumbers;