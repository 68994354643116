export function setCurrentUserAttribs(userObj, stateCallback) {
  stateCallback({
    user_id: userObj.id, 
    name: userObj.attributes.name,
    paymentMethodAdded: userObj.attributes.payment_method_added,
    signupFeePaid: userObj.attributes.signup_fee_paid,
    isProblematic: userObj.attributes.is_problematic,
    paymentMethodValid: userObj.attributes.payment_method_valid,
    stripePaymentIntentId: userObj.attributes.stripe_payment_intent_client_secret,
    creditAmount: userObj.attributes.account_balance,
    agreedToTerms: userObj.attributes.agreed_to_terms
  })
}