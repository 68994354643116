import React from 'react';
import Modal from 'react-bootstrap/Modal'
import Card from 'react-bootstrap/Card'
import GoogleLoginButton from '../../GoogleAuthButtons/GoogleLoginButton/GoogleLoginButton'
import {Link} from 'react-router-dom'
import Spinner from '../../Loaders/Spinner/Spinner'
import {Colors} from '../../../Constants/Style/Colors'
import AppStoresGroup from '../../AppButtons/AppStoresGroup/AppStoresGroup'
const LoginModal = props => {
  function renderLoggingInState() {
    if (props.loading) {
      return (
        <Spinner
          size="big"
          color={Colors.secondary}
        />
      )
    } else {
      return (
        <>
          <p className="mb-4">Login to your existing account</p>
          <GoogleLoginButton onLoggingIn={() => {props.setCurrentlyAuthenticating(true)}} onLoginSuccess={props.handleLogin}></GoogleLoginButton>
        </>
      )
    }
  }
  return (
    <Modal style={{top: '20%'}} size="lg" show={true} onHide={() => {props.toggleLoginOverlay(false)}}>
      <Modal.Header closeButton>
        <Modal.Title>Welcome To Lenny.Mobi</Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
        {renderLoggingInState()}
        <AppStoresGroup/>
      </Modal.Body>
      <Modal.Footer className="justify-content-center">
        <p>New to Lenny? </p>
        <Link onClick={() => {props.toggleLoginOverlay(false)}} className="btn btn-primary" to="/get-started">Sign up</Link>
      </Modal.Footer>
    </Modal>
  )
}

export default LoginModal;