import React, {useState} from 'react';
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import {Link} from 'react-router-dom'

const ErrorModal = props => {
  let [showError, setShowError] = useState(true)
  return (
    <Modal style={{top: '20%'}} size="lg" show={showError} onHide={() => {setShowError(false)}}>
      <Modal.Header closeButton>
        <Modal.Title>There's a small problem</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {props.message}
      </Modal.Body>
      <Modal.Footer className="justify-content-right">
        <Button variant="secondary" onClick={() => {setShowError(false)}}>Close</Button>
        <Link onClick={() => {setShowError(false)}} to={props.correctionPath} className='btn btn-primary'>{props.correctionLabel}</Link>
      </Modal.Footer>
    </Modal>
  )
}

export default ErrorModal;