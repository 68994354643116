import React, {useEffect, useState} from 'react';
import './LoginContainer.css';
import {loginPageData, authenticatedLoginPageDataHandler} from '../../../Constants/SiteData/Paths/LoginPage'
import GoogleLoginButton from '../../GoogleAuthButtons/GoogleLoginButton/GoogleLoginButton'
import {connect} from 'react-redux'
import EntryPoints from '../../EntryPoints/EntryPoints'
import {getUserInfo} from '../../../Services/User'
import AccountReconciliationWizard from '../../AccountReconciliationWizard/AccountReconciliationWizard'
import {scrollToTopOfPage} from '../../../Utilities/DOMutils/DOMutils'
import {setCurrentUserAttribs} from '../../../Actions/User/DispatchUserState'
import Spinner from '../../../Components/Loaders/Spinner/Spinner'
import LoginModal from '../../AppModals/LoginModal/LoginModal'

function LoginContainer(props) {

  let authenticatedLoginPageData = authenticatedLoginPageDataHandler(props.currentUser.name)

  let [currentlyAuthenticating, setCurrentlyAuthenticating] = useState(false)

  useEffect(() => {
    scrollToTopOfPage()
  }, [])

  const handleLogin = (data) => {
    props.loginOverlayHandler(false)
    fetchUserData(data.user_id)
  }

  const fetchUserData = (userId) => {
    setCurrentlyAuthenticating(true)
    getUserInfo(userId).then(res => res.json())
    .then(obj => {
      setCurrentlyAuthenticating(false)
      if (obj && obj.data && obj.data.type === 'user') {
        let currentUser = obj.data
        setCurrentUserAttribs(currentUser, props.setCurrentUser)
      }
    })
  }


  const renderUnauthenticatedInterface = () => {
    return (
      <>
        <div className="login-container-heading-wrapper">
          <h1 className="login-container-heading">{loginPageData.header.title}</h1>
          <h2 className="login-container-subheading">{loginPageData.header.subtitle}</h2>
        </div>
        <div className="login-container-oauth-wrapper">
          {
            currentlyAuthenticating ? 
              <Spinner
                size="big"
              />
            : 
              <GoogleLoginButton signup onLoggingIn={() => {setCurrentlyAuthenticating(true)}} onLoginSuccess={handleLogin}></GoogleLoginButton>
          }
        </div>
      </>
    )
  }

  const accountInGoodStanding = () => {
    if (props.currentUser.paymentMethodAdded && props.currentUser.paymentMethodValid && props.currentUser.signupFeePaid && props.currentUser.agreedToTerms) {
      return true
    } else {
      return false
    }
  }

  const renderUserAccountContext = () => {
    if (accountInGoodStanding()) {
      // render welcome back ui
      return <EntryPoints></EntryPoints>
    } else {
      return (
        <AccountReconciliationWizard refreshUserData={fetchUserData} user={props.currentUser}/>
      )
    }
  }

  const renderAuthenticatedInterface = () => {
    return (
      <>
        <div className="login-container-heading-wrapper">
          <h1 className="login-container-heading">{authenticatedLoginPageData.header.title}</h1>
          <h2 className="login-container-subheading">{authenticatedLoginPageData.header.subtitle}</h2>
        </div>
        <div className="login-container-authenticated-actions-wrapper">
          {renderUserAccountContext()}
        </div>
      </>
    )
  }

  const renderLoginContext = () => {
    if (props.currentUser.loggedIn) {
      return renderAuthenticatedInterface()
    } else {
      return renderUnauthenticatedInterface()
    }
  }

  const renderPathContext = () => {
    if (props.showAsModal) {
      return (
        <LoginModal
          toggleLoginOverlay={props.loginOverlayHandler}
          setCurrentlyAuthenticating={setCurrentlyAuthenticating}
          handleLogin={handleLogin}
          loading={currentlyAuthenticating}
        />
      )
    } else {
      return (
        <div className="login-container-wrapper">
          {renderLoginContext()}
        </div>
      )
    }
  }
  return (
    renderPathContext()
  );
}

const mapStateToProps = state => {
  return {
    currentUser: state.appReducer.app.currentUser.authentication
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setCurrentUser: userObj => {dispatch({
      type: 'setCurrentlyLoggedInUser',
      value: userObj
    })},
    loginOverlayHandler: (bool) => {dispatch({
      type: 'toggleLoginOverlay',
      value: bool
    })}
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer);
