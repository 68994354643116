import React from 'react';
import './AroundTheWorld.css';
import phone from '../../../Assets/LandingPage/shared/worldwide-numbers-phone.png'
import countries from '../../../Assets/LandingPage/shared/country-wheel.svg'

const AroundTheWorld = props => {
  return (
    <div className="around-the-world-wrapper">
      <div className="around-the-world-heading-wrapper">
        <h1 className="around-the-world-heading">Your portal to phone numbers from around the world</h1>
        <h2 className="around-the-world-subheading">Seamlessly manage correspondences with your local and international contacts </h2>
      </div>
      <img className="around-the-world-asset-phone" src={phone}></img>
      <img className="around-the-world-asset-wheel" src={countries}></img>
    </div>
  )
}

export default AroundTheWorld;