import React from 'react';
import './MessageComposeControls.css';
import {expandTextArea} from '../../Utilities/DOMutils/DOMutils'
import InputGroup from 'react-bootstrap/InputGroup'
import FormControl from 'react-bootstrap/FormControl'
import Button from 'react-bootstrap/Button'

const MessageComposeControls = props => {
  return (
    <InputGroup 
      onClick={(e) => {e.stopPropagation()}}
      className="m-0 p-0"
    >
      <FormControl
        as="textarea"
        placeholder="Enter message"
        value={props.messageBody}
        onChange={(e) => {props.onChange(e.target.value); expandTextArea(e.target)}}
      />
      <InputGroup.Append>
        <Button 
          onClick={props.onSend} 
          variant="outline-secondary"
          disabled={!props.messageBody || props.loading ? true : false}
        >
          {props.loading ? 'Sending' :'Send'}
        </Button>
      </InputGroup.Append>
    </InputGroup>
  )
}

export default MessageComposeControls;