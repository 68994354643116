import React, {useEffect} from 'react';
import './MobileNavbarOverlay.css';
import {Link} from 'react-router-dom'
import NavbarLinks from '../NavbarLinks/NavbarLinks'
import brand from '../../../Assets/BrandLogos/lenny-logo.svg'
import CloseButton from '../../../Assets/Reusable/mobile-overlay-close.png'
import {lockBodyScroll, unlockBodyScroll} from '../../../Utilities/DOMutils/DOMutils'

function MobileNavbarOverlay(props) {
  useEffect(() => {
    lockBodyScroll()
    return () => {
      unlockBodyScroll()
    }
  }, [])

  return (
    <div className="mobile-navbar-overlay-wrapper">
      <div className={`mobile-navbar-overlay-content-wrapper`}>
        <div className={`mobile-navbar-overlay-header-wrapper`}>
          <div className={`mobile-navbar-overlay-brand-wrapper`}>
            <Link to={'/'}>
              <img onClick={props.onClick} className={`mobile-navbar-overlay-brand-logo`} src={brand}></img>
            </Link>
          </div>
          <div className={`mobile-navbar-overlay-close-wrapper`}>
            <img onClick={props.onClick} src={CloseButton} className={`mobile-navbar-overlay-close`}></img>
          </div>
        </div>
        <NavbarLinks 
          device={props.device} 
          onClick={props.onClick} 
          currentUser={props.currentUser}
          toggleLoginOverlay={props.toggleLoginOverlay}
        />
      </div>
    </div>
  );
}

export default MobileNavbarOverlay;
