import {setAuthenticationDataToLocalStorage, clearDataAndSignOut} from '../../../Utilities/LocalStorage/UserAuthStorage'


export const setCurrentLoggedInUserInfo = (state, action) => {
  setAuthenticationDataToLocalStorage(action.value)
  return {
    ...state,
    app: {
      ...state.app,
      currentUser: {
        ...state.app.currentUser,
        authentication: {
          ...state.app.currentUser.authentication,
          loggedIn: true, 
          userId: action.value.user_id,
          name: action.value.name,
          paymentMethodAdded: action.value.paymentMethodAdded ? action.value.paymentMethodAdded : false, 
          signupFeePaid: action.value.signupFeePaid ? action.value.signupFeePaid : false,
          isProblematic: action.value.isProblematic ? action.value.isProblematic : false,
          paymentMethodValid: action.value.paymentMethodValid ? action.value.paymentMethodValid : false,
          stripePaymentIntentId: action.value.stripePaymentIntentId ? action.value.stripePaymentIntentId : null,
          agreedToTerms: action.value.agreedToTerms ? action.value.agreedToTerms : false
        },
        credits: {
          ...state.app.currentUser.credits,
          amount: action.value.creditAmount,
        }
      }
    }
  }
}

export const unSetCurrentLoggedInUserInfo = (state) => {
  clearDataAndSignOut()
  return {
    ...state,
    app: {
      ...state.app,
      currentUser: {
        ...state.app.currentUser,
        authentication: {
          ...state.app.currentUser.authentication,
          loggedIn: false, 
          userId: null,
          name: null
        }
      }
    }
  }
}