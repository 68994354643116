import React, {useEffect} from 'react';
import './LandingContainer.css';
import introIphone from '../../../Assets/LandingPage/shared/intro-iphone.png'
import desktopHowItWorksBackground from '../../../Assets/LandingPage/desktop/how-it-works-background.svg'
import mobileHowItWorksBackground from '../../../Assets/LandingPage/mobile/how-it-works-background.svg'
import introBackground from '../../../Assets/LandingPage/desktop/intro-background.svg'
import introMobileBackground from '../../../Assets/LandingPage/mobile/intro-background.svg'
import desktopPricing from '../../../Assets/LandingPage/desktop/pricing-graphic.svg'
import mobilePricing from '../../../Assets/LandingPage/mobile/pricing-background.svg'
import desktopTestimonials from '../../../Assets/LandingPage/desktop/testimonials-graphic.svg'
import {landingPageData} from '../../../Constants/SiteData/Paths/LandingPage'
import ButtonAnchor from '../../AppButtons/ButtonAnchor/ButtonAnchor'
import {scrollToTopOfPage} from '../../../Utilities/DOMutils/DOMutils'

function LandingContainer(props) {
  useEffect(() => {
    scrollToTopOfPage()
  }, [])

  const renderContext = () => {
    if (props.device === 'tablet' || props.device === 'desktop') {
      return (
        [
          <div className="landing-container-img-wrapper" name="lenny-intro">
            <img className="landing-container-img" src={introBackground}/>
            <div className="landing-container-intro">
              <h1 className="landing-container-heading">{landingPageData.intro.blocks[0]}</h1>
              <p className="landing-container-text ">{landingPageData.intro.blocks[1]}</p>
              <div>
                <ButtonAnchor
                  url="/explore"
                  label="Explore"
                />
              </div>
            </div>
            <img className="landing-container-iphone" src={introIphone}/>
          </div>,
          <div className="landing-container-img-wrapper" name="lenny-pricing">
            <img className="landing-container-img" src={desktopHowItWorksBackground}/>
            <div className="landing-container-intro">
              <h1 className="landing-container-heading dark">{landingPageData.intro.blocks[2]}</h1>
              <p className="landing-container-text dark">{landingPageData.intro.blocks[3]}</p>
              <div>
                <ButtonAnchor
                  url="/cost-estimation"
                  label="Pricing"
                />
              </div>
            </div>
          </div>,
          <div className="landing-container-img-wrapper">
            <img className="landing-container-img" src={desktopPricing}/>
            <div className="landing-container-intro override-position">
              <h1 className="landing-container-heading dark">{landingPageData.intro.blocks[4]}</h1>
              <p className="landing-container-text dark">{landingPageData.intro.blocks[5]}</p>
            </div>
          </div>,
          <div className="landing-container-img-wrapper" name="lenny-testimonials">
            <img className="landing-container-img" src={desktopTestimonials}/>
            <p className="landing-container-text landing-container-testimonials">{landingPageData.testimonials.blocks[0]}</p>
          </div>
        ]
      )
    }

    if (props.device === 'mobile') {
      return (
        [
          <div className="landing-container-img-wrapper" name="lenny-intro">
            <img className="landing-container-img" src={introMobileBackground}/>
            <div className="landing-container-intro">
              <h1 className="landing-container-heading">{landingPageData.intro.blocks[0]}</h1>
              <p className="landing-container-text ">{landingPageData.intro.blocks[1]}</p>
              <div>
                <ButtonAnchor
                  url="/explore"
                  label="Explore"
                />
              </div>
            </div>
            <img className="landing-container-iphone" src={introIphone}/>
          </div>,
          <div className="landing-container-img-wrapper" name="lenny-pricing">
            <img className="landing-container-img" src={mobileHowItWorksBackground}/>
            <div className="landing-container-intro">
              <h1 className="landing-container-heading dark">{landingPageData.intro.blocks[2]}</h1>
              <p className="landing-container-text dark">{landingPageData.intro.blocks[3]}</p>
              <div>
                <ButtonAnchor
                  url="/cost-estimation"
                  label="Pricing"
                  classN="purple-btn"
                />
              </div>
            </div>
          </div>,
          <div className="landing-container-img-wrapper">
            <img className="landing-container-img" src={mobilePricing}/>
            <div className="landing-container-intro override-position">
              <p className="landing-container-text dark">{landingPageData.intro.blocks[5]}</p>
            </div>
          </div>,
          <div className="landing-container-img-wrapper" name="lenny-testimonials">
            <img className="landing-container-img" src={desktopTestimonials}/>
            {/* <p className="landing-container-text landing-container-testimonials">{landingPageData.testimonials.blocks[0]}</p> */}
          </div>
        ]
      )
    }
  }

  return (
    <div className="landing-container-wrapper">
      {renderContext()}
    </div>
  );
}

export default LandingContainer;
