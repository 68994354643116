export const addUniqueNodeToEndOfArray = (targetObj, arr) => {
  let newArray = [...arr]
  let arrayObjectsValid = true
  const valIsUnique = arr.every(val => {
    if (!val.hasOwnProperty('id') || !targetObj.hasOwnProperty('id')) {
      arrayObjectsValid = false
    }
    return targetObj.id !== val.id
  })
  if (valIsUnique) {
    newArray.push(targetObj);
  } 
  
  if (arrayObjectsValid) {
    return newArray
  } else {
    return false
  }
}