export const loginPageData = {
  header: {
    title: 'Welcome to Lenny',
    subtitle: 'We are thrilled to have you'
  }
}

export const authenticatedLoginPageDataHandler = (name) => {
  return {
    header: {
      title: `Hi ${name}!`,
      subtitle: 'Lets get started'
    }
  }
}