import React, {useEffect} from 'react';
import './Explore.css';
import AroundTheWorld from '../../../Components/LandingPage/AroundTheWorld/AroundTheWorld'
import PrivacyCommitment from '../../../Components/LandingPage/PrivacyCommitment/PrivacyCommitment'
import RealTime from '../../../Components/LandingPage/RealTime/RealTime'
import {scrollToTopOfPage} from '../../../Utilities/DOMutils/DOMutils'
import Pricing from '../../LandingPage/Pricing/Pricing'

const Explore = props => {
  useEffect(() => {
    scrollToTopOfPage()
  }, [])

  function renderBlocks() {
    return ([
      <AroundTheWorld/>,
      <PrivacyCommitment/>,
      <RealTime/>,
      <Pricing/>
    ])
  }
  return (
    <div className="landing-container-wrapper">
      {renderBlocks()}
    </div>
  )
}

export default Explore;