export const navbarData = {
  links: {
    list: [
      {
        label: 'About',
        hasPath: false,
        id: 'aboutPath',
        path: null,
        scrollTo: 'lenny-intro'
      },
      {
        label: 'Pricing',
        hasPath: false,
        id: 'pricingPath',
        path: null,
        scrollTo: 'lenny-pricing'
      },
      {
        label: 'Testimonials',
        hasPath: false,
        id: 'testimonialsPath',
        path: null,
        scrollTo: 'lenny-testimonials'
      },
      {
        label: 'Explore',
        hasPath: true,
        id: 'explorePath',
        path: '/explore'
      },
      {
        label: 'Get Started',
        hasPath: true,
        id: 'loginPath',
        path: '/get-started'
      }
    ]
  }
}


export const authenticatedNavbarData = {
  links: {
    list: [
      {
        label: 'Load Credits',
        hasPath: true,
        id: 'loadCredits',
        path: '/self/load-account'
      },
      {
        label: 'Numbers',
        hasPath: true,
        id: 'numbersPath',
        path: '/self/phone-numbers'
      },
      {
        label: 'Account',
        hasPath: true,
        id: 'accountSettings',
        path: '/self/account-settings'
      },
      {
        label: 'Support',
        hasPath: true,
        id: 'support',
        path: '/self/support'
      },
      {
        label: 'Log out',
        hasPath: true,
        id: 'logoutPath',
        path: '/logout'
      },
    ]
  }
}