import React from 'react';
import './CountryDropdown.css';
import Select from 'react-select'

const CountryDropdown = props => {

  let options = computeOptions()

  function computeOptions() {
    if (props.forMessaging) {
      return (
        [
          { value: '+44', label: 'United Kingdom' },
          { value: '+1', label: 'Canada' },
          { value: '+1', label: 'United States' },
          { value: '+61', label: 'Australia' },
          { value: '+32', label: 'Belgium' },
          { value: '+56', label: 'Chile' },
          { value: '+45', label: 'Denmark' },
          { value: '+33', label: 'France' },
          { value: '+972', label: 'Israel' },
          { value: '+1', label: 'Puerto Rico' },
          { value: '+27', label: 'South Africa' },
        ]
      )
    } else {
      return (
        [
          { value: 'GB', label: 'United Kingdom' },
          { value: 'CA', label: 'Canada' },
          { value: 'US', label: 'United States' },
          { value: 'AU', label: 'Australia' },
          { value: 'BE', label: 'Belgium' },
          { value: 'CL', label: 'Chile' },
          { value: 'DK', label: 'Denmark' },
          { value: 'FR', label: 'France' },
          { value: 'IL', label: 'Israel' },
          { value: 'PR', label: 'Puerto Rico' },
          { value: 'ZA', label: 'South Africa' },
        ]
      )
    }
  }

  const handleChange = (e) => {
    props.onSelect(e)
  }

  const renderContext = () => {
    if (props.device === 'mobile') {
      return (
        <Select 
          onChange={handleChange} 
          options={options} 
          name="select country"
          placeholder="Select Country"
          isClearable={true}
          isSearchable={false}
        />
      )
    } else {
      return (
        <Select 
          onChange={handleChange} 
          options={options} 
          isClearable={true}
          isSearchable={true}
          name="select country"
          placeholder="Select Country"
        />
      )
    }
  }
  return (
    renderContext()
  )
}

export default CountryDropdown;