// PLURALIZER

// pluralize(0, 'apple'); // 'apples'
// pluralize(1, 'apple'); // 'apple'
// pluralize(2, 'apple'); // 'apples'
// pluralize(2, 'person', 'people'); // 'people'
// autoPluralize(2, 'person')); // 'people'

const pluralize = (val, word, plural = word + 's') => {
  const _pluralize = (num, word, plural = word + 's') =>
    [1, -1].includes(Number(num)) ? word : plural;
  if (typeof val === 'object') return (num, word) => _pluralize(num, word, val[word]);
  return _pluralize(val, word, plural);
};
const PLURALS = {
  person: 'people',
  radius: 'radii'
};
export const autoPluralize = pluralize(PLURALS)

export const stringsAreEqual = (str1, str2) => {
  if (str1.localeCompare(str2) === 0) {
    return true
  } else {
    return false
  }
}

export const cleanPhoneNumber = str => {
  return str.replace('-', '').replace(' ', '')
}

export const translateCountryCodeToFriendlyString = (countryCode) => {
  switch(countryCode) {
    case 'CA':
      return 'Canada'
    case 'GB':
      return 'England'
    case 'US':
      return 'United States'
    case 'AU':
      return 'Australia'
    case 'BE':
      return 'Belgium'
    case 'CL':
      return 'Chile'
    case 'DK':
      return 'Denmark'   
    case 'FR':
      return 'France'
    case 'IL':
      return 'Israel'
    case 'PR':
      return 'Puerto Rico'
    case 'ZA':
      return 'South Africa'
    default: 
      return 'Unknown Country'
  }
}

export const translateFriendlyStringToCountryCode = (countryString) => {
  switch(countryString) {
    case 'Canada':
      return 'CA'
    case 'England':
      return 'GB'
    case 'United States':
      return 'US'
    case 'Australia':
      return 'AU'
    case 'Belgium':
      return 'BE'
    case 'Chile':
      return 'CL'
    case 'Denmark':
      return 'DK'   
    case 'France':
      return 'FR'
    case 'Israel':
      return 'IL'
    case 'Puerto Rico':
      return 'PR'
    case 'South Africa':
      return 'ZA'
    default: 
      return 'Unknown Country'
  }
}

export const phoneNumberHasDialingCodeAttached = (phoneNumber) => {
  let occurancesOfPlusSign = phoneNumber.split("+").length-1
  if ( occurancesOfPlusSign == 1) {
    // if there is only 1 plus, the dialing code is there
    return true
  } else {
    return false
  }
}