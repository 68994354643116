import React from 'react';
import './PhoneNumberBuyConfirmation.css';
import Button from '../AppButtons/Button/Button'

const PhoneNumberBuyConfirmation = props => {

  const renderGranularCosts = () => {
    if (props.selectedNumber.sms_pricing &&  props.selectedNumber.sms_pricing.inbound_cost) {
      return (
        [
          <p className="phone-number-buy-confirmation-info">Per incoming message: ${parseFloat(props.selectedNumber.sms_pricing.inbound_cost.current_price).toFixed(3)}</p>,
          <p className="phone-number-buy-confirmation-info">Per outgoing message: ${parseFloat(props.selectedNumber.sms_pricing.average_outbound_cost).toFixed(3)}</p>
        ]
      )
    } else {
      return <p className="phone-number-buy-confirmation-info">unable to calculate incoming and outgoing message costs</p>
    }

  }

  return (
    <div className="phone-number-buy-confirmation-wrapper">
      <div className="phone-number-buy-confirmation-header-wrapper">
        <h2 className="phone-number-buy-confirmation-header">{props.selectedNumber.friendly_name} is a great number! Here is the cost breakdown</h2>
      </div>
      <div className="phone-number-buy-confirmation-cost-breakdown-wrapper">
        <p className="phone-number-buy-confirmation-info">Monthly cost: ${props.selectedNumber.monthly_cost}</p>
        {renderGranularCosts()}
        <p className="phone-number-buy-confirmation-total-billable">Total Billable now: ${props.selectedNumber.monthly_cost}</p>
      </div>
      <div className="phone-number-buy-confirmation-controls">
        <Button clicked={props.onDeny} title="Back" name={`next-button ${props.loading ? 'loading' : ''}`} disabled={ props.loading ? true : false }/>
        <Button clicked={() => {props.onConfirm(props.selectedNumber)}} title="Confirm" name={`next-button ${props.loading ? 'loading' : ''}`} disabled={ props.loading ? true : false }/>
      </div>
    </div>
  )
}

export default PhoneNumberBuyConfirmation;