import React from 'react';
import './PhoneNumberBuyFailure.css';
import ButtonAnchor from '../AppButtons/ButtonAnchor/ButtonAnchor'

const PhoneNumberBuyFailure = props => {
  return (
    <div className="phone-number-buy-failure-wrapper">
      <div className="phone-number-buy-failure-info-wrapper">
        <p className="phone-number-buy-failure-info">{props.error}</p>
      </div>
      <div className="phone-number-buy-failure-controls-wrapper">
        <a className="btn-anchor next-button" href="mailto:contact@restarone.com">Email Support</a>
      </div>
    </div>
  )
}

export default PhoneNumberBuyFailure;