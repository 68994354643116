import React, {useState, useEffect} from 'react';
import './Navbar.css';
import FullSizeNavbar from '../FullSizeNavbar/FullSizeNavbar'
import MobileNavbar from '../MobileNavbar/MobileNavbar'
import MobileNavbarOverlay from '../MobileNavbarOverlay/MobileNavbarOverlay'




function Navbar(props) {
  let [overlayOpen, overlayOpenSetter] = useState(false)
  
  const toggleOverlay = () => {
    overlayOpenSetter(!overlayOpen)
  }

  const renderContext = () => {
    if (props.device === 'desktop' || props.device === 'tablet') {
      return (
        <FullSizeNavbar 
          device={props.device} 
          currentUser={props.currentUser}
          toggleLoginOverlay={props.toggleLoginOverlay}
        />
      )
    } else {
      return (
        <MobileNavbar 
          onClick={toggleOverlay}
        />
      )
    }
  }


  const renderMobileOverlay = () => {
    if (overlayOpen) {
      return (
        <MobileNavbarOverlay 
          device={props.device} 
          currentUser={props.currentUser} 
          onClick={toggleOverlay}
          toggleLoginOverlay={props.toggleLoginOverlay}
        />
      )
    }
  }

  return (
    [
      renderContext(),
      renderMobileOverlay(),
    ]
  );
}

export default Navbar;
