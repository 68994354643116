import {getApiRoot} from '../Constants/ApiRoots'
import {getAuthenticationDataFromLocalStorage} from '../Utilities/LocalStorage/UserAuthStorage'

export const login = (profileObj, tokenObj) => {
  return new Promise((resolve, reject) => {
    let endpoint = getApiRoot() + `/api/core/v1/google_sessions`;
    fetch(endpoint, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
      body: JSON.stringify({
        profileObj: profileObj, 
        tokenObj: tokenObj, 
      }) 
    })
      .then(e => {
        if (e.ok) {
          // console.log(e)
          resolve(e);
        } else {
          // console.log(e)
          reject(e);
        }
      })
      .catch(e => console.log("error::", e));
  });
};


export const logOut = (userId) => {
  return new Promise((resolve, reject) => {
    let endpoint = getApiRoot() + `/api/core/v1/sessions/${userId}`;
    fetch(endpoint, {
      method: "DELETE",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
    })
      .then(e => {
        if (e.ok) {
          // console.log(e)
          resolve(e);
        } else {
          // console.log(e)
          reject(e);
        }
      })
      .catch(e => console.log("error::", e));
  });
};

export const getUserInfo = (userId) => {
  return new Promise((resolve, reject) => {
    let endpoint = getApiRoot() + `/api/core/v1/users/${userId}`;
    fetch(endpoint, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
    })
      .then(e => {
        if (e.ok) {
          // console.log(e)
          resolve(e);
        } else {
          // console.log(e)
          reject(e);
        }
      })
      .catch(e => console.log("error::", e));
  });
};


export const updateUser = (action, userId, options = {}) => {
  return new Promise((resolve, reject) => {
    let endpoint = getApiRoot() + `/api/core/v1/users/${userId}`;
    fetch(endpoint, {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
      body: JSON.stringify({
        action_type: action, 
      }) 
    })
      .then(e => {
        if (e.ok) {
          // console.log(e)
          resolve(e);
        } else {
          // console.log(e)
          reject(e);
        }
      })
      .catch(e => console.log("error::", e));
  });
};

export const provisionPhoneNumberForUser = (options) => {
  return new Promise((resolve, reject) => {
    let endpoint = getApiRoot() + `/api/core/v1/twilio_phone_numbers`;
    fetch(endpoint, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
      body: JSON.stringify({
        ...options
      }) 
    })
      .then(e => {
        if (e.ok) {
          // console.log(e)
          resolve(e);
        } else {
          // console.log(e)
          reject(e);
        }
      })
      .catch(e => console.log("error::", e));
  });
}

export const loadAccount = (amountInCents) => {
  return new Promise((resolve, reject) => {
    let endpoint = getApiRoot() + `/api/core/v1/users/${getAuthenticationDataFromLocalStorage().user_id}/reloads`;
    fetch(endpoint, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
      body: JSON.stringify({
        amount_in_cents: amountInCents
      }) 
    })
      .then(e => {
        if (e.ok) {
          // console.log(e)
          resolve(e);
        } else {
          // console.log(e)
          reject(e);
        }
      })
      .catch(e => console.log("error::", e));
  });
}

export const listOwnedPhoneNumbers = () => {
  return new Promise((resolve, reject) => {
    let endpoint = getApiRoot() + `/api/core/v1/users/${getAuthenticationDataFromLocalStorage().user_id}/phone_numbers`;
    fetch(endpoint, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
    })
      .then(e => {
        if (e.ok) {
          // console.log(e)
          resolve(e);
        } else {
          // console.log(e)
          reject(e);
        }
      })
      .catch(e => console.log("error::", e));
  });
};

export const deleteUserAccount = () => {
  return new Promise((resolve, reject) => {
    let endpoint = getApiRoot() + `/api/core/v1/users/${getAuthenticationDataFromLocalStorage().user_id}`;
    fetch(endpoint, {
      method: "DELETE",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
    })
      .then(e => {
        if (e.ok) {
          // console.log(e)
          resolve(e);
        } else {
          // console.log(e)
          reject(e);
        }
      })
      .catch(e => console.log("error::", e));
  });
};

export const getStripeSetupIntentForCreditcardUpdate = () => {
  return new Promise((resolve, reject) => {
    let endpoint = getApiRoot() + `/api/core/v1/users/${getAuthenticationDataFromLocalStorage().user_id}/edit?action_type=update-creditcard-details`;
    fetch(endpoint, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
    })
      .then(e => {
        if (e.ok) {
          // console.log(e)
          resolve(e);
        } else {
          // console.log(e)
          reject(e);
        }
      })
      .catch(e => console.log("error::", e));
  });
};

export const setLatestAddedPaymentMethodAsDefault = () => {
  return new Promise((resolve, reject) => {
    let endpoint = getApiRoot() + `/api/core/v1/users/${getAuthenticationDataFromLocalStorage().user_id}/edit?action_type=update-default-payment-method`;
    fetch(endpoint, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
    })
      .then(e => {
        if (e.ok) {
          // console.log(e)
          resolve(e);
        } else {
          // console.log(e)
          reject(e);
        }
      })
      .catch(e => console.log("error::", e));
  });
};


export const showOwnedPhoneNumbers = (phoneId) => {
  return new Promise((resolve, reject) => {
    let endpoint = getApiRoot() + `/api/core/v1/users/${getAuthenticationDataFromLocalStorage().user_id}/phone_numbers/${phoneId}`;
    fetch(endpoint, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
    })
      .then(e => {
        if (e.ok) {
          // console.log(e)
          resolve(e);
        } else {
          // console.log(e)
          reject(e);
        }
      })
      .catch(e => console.log("error::", e));
  });
};

export const updateOwnedPhoneNumber = (phoneId, options) => {
  return new Promise((resolve, reject) => {
    let endpoint = getApiRoot() + `/api/core/v1/users/${getAuthenticationDataFromLocalStorage().user_id}/phone_numbers/${phoneId}`;
    fetch(endpoint, {
      method: "PATCH",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
      body: JSON.stringify({
        action_type: options.actionType,
      }) 
    })
      .then(e => {
        if (e.ok) {
          // console.log(e)
          resolve(e);
        } else {
          // console.log(e)
          reject(e);
        }
      })
      .catch(e => console.log("error::", e));
  });
};

export const setGlobalVoiceRedirect = (options) => {
  return new Promise((resolve, reject) => {
    let endpoint = getApiRoot() + `/api/core/v1/users/${getAuthenticationDataFromLocalStorage().user_id}`;
    fetch(endpoint, {
      method: "PATCH",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
      body: JSON.stringify({
        action_type: 'set-global-voice-redirect',
        country_code: options.countryCode,
        phone_number: options.phoneNumber
      }) 
    })
      .then(e => {
        if (e.ok) {
          // console.log(e)
          resolve(e);
        } else {
          // console.log(e)
          reject(e);
        }
      })
      .catch(e => console.log("error::", e));
  });
};

export const setAccountSlackIntegration = (options) => {
  return new Promise((resolve, reject) => {
    let endpoint = getApiRoot() + `/api/core/v1/users/${getAuthenticationDataFromLocalStorage().user_id}/slack_integrations`;
    fetch(endpoint, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
      body: JSON.stringify({
        temp_code: options.tempCode,
        integration_for: 'account',
      }) 
    })
      .then(e => {
        if (e.ok) {
          // console.log(e)
          resolve(e);
        } else {
          // console.log(e)
          reject(e);
        }
      })
      .catch(e => console.log("error::", e));
  });
};

export const accountSlackIntegrations = () => {
  return new Promise((resolve, reject) => {
    let endpoint = getApiRoot() + `/api/core/v1/users/${getAuthenticationDataFromLocalStorage().user_id}/slack_integrations`;
    fetch(endpoint, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
      }
    })
      .then(e => {
        if (e.ok) {
          // console.log(e)
          resolve(e);
        } else {
          // console.log(e)
          reject(e);
        }
      })
      .catch(e => console.log("error::", e));
  });
};

export const deleteAccountSlackIntegration = (id) => {
  return new Promise((resolve, reject) => {
    let endpoint = getApiRoot() + `/api/core/v1/users/${getAuthenticationDataFromLocalStorage().user_id}/slack_integrations/${id}`;
    fetch(endpoint, {
      method: "DELETE",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
      }
    })
      .then(e => {
        if (e.ok) {
          // console.log(e)
          resolve(e);
        } else {
          // console.log(e)
          reject(e);
        }
      })
      .catch(e => console.log("error::", e));
  });
};

export const accountSlackIntegrationMuteToggler = (id) => {
  return new Promise((resolve, reject) => {
    let endpoint = getApiRoot() + `/api/core/v1/users/${getAuthenticationDataFromLocalStorage().user_id}/slack_integrations/${id}`;
    fetch(endpoint, {
      method: "PATCH",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
      body: JSON.stringify({
        action_type: 'toggle-mute',
      }) 
    })
      .then(e => {
        if (e.ok) {
          // console.log(e)
          resolve(e);
        } else {
          // console.log(e)
          reject(e);
        }
      })
      .catch(e => console.log("error::", e));
  });
};

export const makePhoneCall = (phoneId, options) => {
  return new Promise((resolve, reject) => {
    let endpoint = getApiRoot() + `/api/core/v1/users/${getAuthenticationDataFromLocalStorage().user_id}/phone_numbers/${phoneId}/calls`;
    fetch(endpoint, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
      body: JSON.stringify({
        recipient_number: options.recipientNumber,
      }) 
    })
      .then(e => {
        if (e.ok) {
          // console.log(e)
          resolve(e);
        } else {
          // console.log(e)
          reject(e);
        }
      })
      .catch(e => console.log("error::", e));
  });
};

export const listConversations = (phoneNumberId) => {
  return new Promise((resolve, reject) => {
    let endpoint = getApiRoot() + `/api/core/v1/users/${getAuthenticationDataFromLocalStorage().user_id}/phone_numbers/${phoneNumberId}/conversations`;
    fetch(endpoint, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
    })
      .then(e => {
        if (e.ok) {
          // console.log(e)
          resolve(e);
        } else {
          // console.log(e)
          reject(e);
        }
      })
      .catch(e => console.log("error::", e));
  });
};

export const showConversation = (phoneNumberId, conversationId) => {
  return new Promise((resolve, reject) => {
    let endpoint = getApiRoot() + `/api/core/v1/users/${getAuthenticationDataFromLocalStorage().user_id}/phone_numbers/${phoneNumberId}/conversations/${conversationId}`;
    fetch(endpoint, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
    })
      .then(e => {
        if (e.ok) {
          // console.log(e)
          resolve(e);
        } else {
          // console.log(e)
          reject(e);
        }
      })
      .catch(e => console.log("error::", e));
  });
};

export const createConversation = (phoneNumberId, options) => {
  return new Promise((resolve, reject) => {
    let endpoint = getApiRoot() + `/api/core/v1/users/${getAuthenticationDataFromLocalStorage().user_id}/phone_numbers/${phoneNumberId}/conversations`;
    fetch(endpoint, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
      body: JSON.stringify({
        message_body: options.messageBody,
        recipient_number: options.recipientNumber
      }) 
    })
      .then(e => {
        if (e.ok) {
          // console.log(e)
          resolve(e);
        } else {
          // console.log(e)
          reject(e);
        }
      })
      .catch(e => console.log("error::", e));
  });
};

export const updateConversationTitle = (phoneNumberId, conversationId, options) => {
  return new Promise((resolve, reject) => {
    let endpoint = getApiRoot() + `/api/core/v1/users/${getAuthenticationDataFromLocalStorage().user_id}/phone_numbers/${phoneNumberId}/conversations/${conversationId}`;
    fetch(endpoint, {
      method: "PATCH",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
      body: JSON.stringify({
        title: options.title,
        action_type: 'set-title'
      }) 
    })
      .then(e => {
        if (e.ok) {
          // console.log(e)
          resolve(e);
        } else {
          // console.log(e)
          reject(e);
        }
      })
      .catch(e => console.log("error::", e));
  });
};

export const setConversationAsRead = (phoneNumberId, conversationId) => {
  return new Promise((resolve, reject) => {
    let endpoint = getApiRoot() + `/api/core/v1/users/${getAuthenticationDataFromLocalStorage().user_id}/phone_numbers/${phoneNumberId}/conversations/${conversationId}`;
    fetch(endpoint, {
      method: "PATCH",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
      body: JSON.stringify({
      
        action_type: 'set-as-read'
      }) 
    })
      .then(e => {
        if (e.ok) {
          // console.log(e)
          resolve(e);
        } else {
          // console.log(e)
          reject(e);
        }
      })
      .catch(e => console.log("error::", e));
  });
};

export const deleteConversation = (phoneNumberId, conversationId) => {
  return new Promise((resolve, reject) => {
    let endpoint = getApiRoot() + `/api/core/v1/users/${getAuthenticationDataFromLocalStorage().user_id}/phone_numbers/${phoneNumberId}/conversations/${conversationId}`;
    fetch(endpoint, {
      method: "DELETE",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
    })
      .then(e => {
        if (e.ok) {
          // console.log(e)
          resolve(e);
        } else {
          // console.log(e)
          reject(e);
        }
      })
      .catch(e => console.log("error::", e));
  });
};

export const sendMessage = (phoneNumberId, conversationId, options) => {
  return new Promise((resolve, reject) => {
    let endpoint = getApiRoot() + `/api/core/v1/users/${getAuthenticationDataFromLocalStorage().user_id}/phone_numbers/${phoneNumberId}/conversations/${conversationId}/messages`;
    fetch(endpoint, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
      body: JSON.stringify({
        message_body: options.messageBody,
      }) 
    })
      .then(e => {
        if (e.ok) {
          // console.log(e)
          resolve(e);
        } else {
          // console.log(e)
          reject(e);
        }
      })
      .catch(e => console.log("error::", e));
  });
};



