import React from 'react'
import './TextAreaInput.css'

const TextAreaInput = props => {
  return (
    <textarea
      ref={props.useRef}
      disabled={props.disabled}
      rows='1'
      id={props.id}
      className={`text-area-input ${props.name}`}
      placeholder={props.placeholder}
      value={props.value}
      onChange={props.onChange}
      onKeyDown={props.onKeyDown}
    />
  )
}

export default TextAreaInput;