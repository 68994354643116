import React from 'react';
import './SignupFee.css';
import Button from '../AppButtons/Button/Button'

const SignupFee = props => {
  const appendLoaderClass = () => {
    if (props.showAgreeLoader) {
      return 'loading'
    }
  }
  return (
    <div className="signupfee-wrapper">
      <div className="signupfee-info-wrapper">
        <p className="signupfee-info">To complete your registration, please authorize Lenny to charge the one-time signup fee. </p>
        <p className="signupfee-price">Total: $5.49 USD</p>
      </div>
      <div className="signupfee-info-controls">
        <Button disabled={props.disagreeDisabled} title="No, delete my account" name="signupfee-disagree-button" clicked={props.onDisagree}/>
        <Button disabled={props.agreeDisabled} title="Authorize" name={`signupfee-agree-button ${appendLoaderClass()}`} clicked={props.onAgree}/>
      </div>
    </div>
  )
}

export default SignupFee;