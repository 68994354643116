import React from 'react';
import { GoogleLogin } from 'react-google-login';
import {login} from '../../../Services/User'
import {clientId} from '../../../Constants/GoogleOAuth'


function GoogleLoginButton(props) {

  function handleSuccess(e) {
    props.onLoggingIn()
    login(e.profileObj, e.tokenObj).then(res => res.json())
    .then(obj => {
      if (obj.user_id) {
        props.onLoginSuccess(obj)
      } else {
        // handle auth error
        alert(obj.status)
      }
      
    })
  }

  return (
    <GoogleLogin 
      prompt="consent" 
      onSuccess={handleSuccess}
      onFailure={(e) => {console.log(e.details)}}
      className="GoogleLogin" 
      clientId={clientId}
      buttonText={ props.signup ? 'Sign up with Google' : 'Sign in with Google'}
    />
  );
}

export default GoogleLoginButton;