import React from 'react';
import './PaymentIntentWizard.css';
import StripeCreditCard from '../StripeCreditCard/StripeCreditCard'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
const stripePromise = loadStripe('pk_live_51H2RvWAjYzdB8OqT2fgE3WokzZrKp4qYTmh39ZS10buKjnx4jFCsSsw8FSJuSl0LhR6fV3V59dAYAUQfQgJ2V7py00T9XbvV51')

function PaymentIntentWizard(props) {
  function renderContext() {
    if (props.updatingPaymentMethod) {
      return (
        <p className="payment-intent-wizard-info">Please enter your new credit card details. Your previous card details will be removed</p>
      )
    } else {
      return (
        <p className="payment-intent-wizard-info">To continue, please add your credit card. We won't charge you unless we explicitly ask you to make a payment</p>
      )
    }
  }
  return (
    <div className="payment-intent-wizard-wrapper">
      <div className="payment-intent-wizard-info-wrapper">
        {renderContext()}
      </div>
      <div className="payment-intent-card-wrapper">
        <Elements stripe={stripePromise}>
          <StripeCreditCard onSuccess={props.onSuccess} paymentIntent={props.paymentIntent}/>
        </Elements>
      </div>
    </div>
  );
}

export default PaymentIntentWizard;
