import React from 'react';
import './FullSizeNavbar.css';
import NavbarLinks from  '../NavbarLinks/NavbarLinks'
import {Link} from 'react-router-dom'
import BrandLogo from '../../../Assets/BrandLogos/lenny-logo.svg'

function FullSizeNavbar(props) {
  
  return (
    <div className="fullsize-navbar-wrapper">
      <div className="fullsize-navbar-brand-wrapper">
        <Link className="fullsize-navbar-brand-image-wrapper" to='/'>
          <img className="fullsize-navbar-brand-image" src={BrandLogo}></img>
        </Link>
      </div>
      <div className="fullsize-navbar-links-wrapper">
        <NavbarLinks 
          device={props.device} 
          currentUser={props.currentUser}
          toggleLoginOverlay={props.toggleLoginOverlay}
        />
      </div>
    </div>
  );
}

export default FullSizeNavbar;
