import React from 'react';
import './Message.css';
import {showElapsedTimeFromCurrentTime} from '../../Utilities/TimeUtils/TimeUtilities'
import Card from 'react-bootstrap/Card'

const Message = props => {
  let {message} = props
  
  function renderMessageContext() {
    if (message.attributes.outgoing) {
      return (
        'outgoing-msg'
      )
    } else {
      return (
        'incoming-msg'
      )
    }
  }

  return (
    <Card 
      className={
        `${renderMessageContext()} 
        mb-2 mt-2`
      }
      bg={message.attributes.outgoing ? 'light' : 'secondary'}
      text={message.attributes.outgoing ? 'dark' : 'white'}
    >
      <Card.Body className="text-left">{message.attributes.body}</Card.Body>
      <Card.Subtitle 
        className={`m-2 text-right ${message.attributes.outgoing ? 'text-muted' : 'white'}`}
      >
        {showElapsedTimeFromCurrentTime(message.attributes.created_at)}
      </Card.Subtitle>
    </Card>
  )
}

export default Message;