import React, {useState, useEffect} from 'react';
import './FullSizeConversationIndex.css';
import ConversationListItem from '../ConversationListItem/ConversationListItem'
import {Link} from 'react-router-dom'
import Conversation from '../Conversation/Conversation'
import Button from 'react-bootstrap/Button'
import InputGroup from 'react-bootstrap/InputGroup'
import FormControl from 'react-bootstrap/FormControl'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Alert from 'react-bootstrap/Alert'
import Spinner from '../../Components/Loaders/Spinner/Spinner'
import {Colors} from '../../Constants/Style/Colors'

const FullSizeConversationIndex = props => {
  let {phoneNumberId, conversations, updateMessageIndex, onDelete, onTitleUpdate, updateConversation, onCall} = props
  let [selectedConversation, setSelectedConversation] = useState(null)
  let [inSearchMode, setSearchMode] = useState(false)
  let [searchQuery, setSearchQuery] = useState('')

  useEffect(() => {
    // when conversations update as a result of the websocket, we replace the selected conversation with the latest version of it
    updateSelectedConversation()
  }, [conversations])

  useEffect(() => {
    // lockBodyScroll()
  }, [])

  useEffect(() => {
    // for triggering search mode
    if (searchQuery) {
      setSearchMode(true)
      setSelectedConversation(null)
    } else {
      setSearchMode(false)
    }
  }, [searchQuery])

  function updateSelectedConversation() {
    if (selectedConversation) {
      let latestVersion = conversations.find(c => c.id === selectedConversation.id)
      if (latestVersion) {
        setSelectedConversation(latestVersion)
      }
    }
  }

  function filterConversations(conversations) {
    return conversations.filter((c) => {
      if (c.attributes.title.toLowerCase().includes(searchQuery.toLowerCase()) || c.attributes.recipient_number.includes(searchQuery)) {
        return c
      }
    })
  }
  
  function renderConversationsContext() {
    if (!inSearchMode) {
      // return the conversations without filtering if not searching
      return renderConversations(conversations)
    } else {
      return renderConversations(filterConversations(conversations))
    }
  }

  function renderConversations(conversations) {
    if (!props.loading) {
      if (conversations.length > 0) {
        return conversations.map((c) => {
          return (
            <ConversationListItem 
              key={c.id} 
              phoneNumberId={phoneNumberId} 
              conversation={c}
              updateMessageIndex={updateMessageIndex}
              onDelete={onDelete}
              onTitleUpdate={onTitleUpdate}
              updateConversation={updateConversation}
              onCall={onCall}
              splitViewContext
              onClick={(conversation) => {setSelectedConversation(conversation)}}
              currentlySelected={selectedConversation && c.id === selectedConversation.id ? true : false}
              device={props.device}
              orientation={props.orientation}
            />
          )
        })
      } else {
        if (!inSearchMode) {
          return (
            <Alert variant='warning' className="text-center m-2">
              No conversations, yet.
            </Alert>
          )
        } else {
          return (
            <Alert variant='warning' className="text-center m-2">
              No conversations were found by title or recipient number
            </Alert>
          )
        }
      }
    } else {
      return <Spinner size="big" color={Colors.primary}/>
    }
  }

  function renderConversation() {
    if (selectedConversation) {
      return (
        <Conversation
          phoneNumberId={phoneNumberId}
          conversation={selectedConversation}
          refreshIndex={updateMessageIndex}
          bypassContainerFocus
          fullsizeContext
          device={props.device}
          orientation={props.orientation}
          updateSelf={updateConversation}
        />
      )
    }
  }

  function calculateHeight() {
    if (props.device === 'tablet') {
      if (props.orientation === 'portrait') {
        return '79vh'
      } else {
        return '80vh'
      }
    }
    // default desktop
    return '79vh'
  }
  
  return (
    <Container className="pt-1" fluid>
      <Row>
        <Col 
          className="p-0 m-0"
          lg={props.device === 'tablet' ? 4 : 3}
          sm={5}
        >
          <Container className="mt-2 mb-2 ml-2 mr-2">
            {props.phoneNumber ?  <p>Sending From {props.phoneNumber.attributes.number}</p> : ''}
            <Row>
              <Col
              className="m-0 p-0"
              >
                <InputGroup onClick={(e) => {e.stopPropagation()}}>
                  <FormControl
                    placeholder="Search"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />
                  <InputGroup.Append className="mr-4">
                    <Button onClick={() => {setSearchQuery('')}} variant="outline-secondary">Clear</Button>
                  </InputGroup.Append>
                </InputGroup>
              </Col>
              <Col
                className="m-0 p-0"
              >
                <Link to={`/self/phone-numbers/${phoneNumberId}/conversations/new`}>
                  <Button
                    className="ml-0"
                  >
                    New Conversation
                  </Button>
                </Link>
              </Col>
            </Row>
          </Container>
          <Container style={{overflowY: 'scroll', height: calculateHeight()}}>
            {renderConversationsContext()}
          </Container>
        </Col>
        <Col 
          className="p-0 m-0"
          lg={props.device === 'tablet' ? 8 : 9}
          sm={7}
        >
          <Container fluid className="pl-2 pr-2 m-0">
            {renderConversation()}
          </Container>
        </Col>
      </Row>
    </Container>
  )
}

export default FullSizeConversationIndex;