import React, {useRef, useEffect, useState} from 'react';
import './Conversation.css';
import Message from '../Message/Message'
import {scrollToBottom, scrollIntoView, expandTextArea} from '../../Utilities/DOMutils/DOMutils'
import TextAreaInput from '../InputFields/TextAreaInput/TextAreaInput'
import Button from '../AppButtons/Button/Button'
import {sendMessage, setConversationAsRead} from '../../Services/User'
import MessageComposeControls from '../MessageComposeControls/MessageComposeControls'
import Container from 'react-bootstrap/Container'

const Conversation = props => {
  
  let {phoneNumberId, conversation} = props
  let messageContainer = useRef(null)
  let conversationContainer = useRef(null)

  let [messageBody, setMessageBody] = useState('')
  let [loading, setLoading] = useState(false)

  useEffect(() => {
    // set convo as read
    if (conversation.attributes.unread) {
      setConversationAsRead(phoneNumberId, conversation.id).then(res => {props.updateSelf(conversation.id)})
    }
  }, [conversation.id])

  useEffect(() => {
    handleFocusContext()
  }, [messageContainer, conversation.attributes.messages])

  function handleFocusContext() {
    if (!props.bypassContainerFocus) {
      scrollIntoView(conversationContainer)
    }
    scrollToBottom(messageContainer)
  }

  function sendMessageHandler() {
    setLoading(true)
    sendMessage(phoneNumberId, conversation.id, {messageBody: messageBody}).then(res => res.json())
    .then(obj => {
      setLoading(false)
      if (obj.data) {
        setMessageBody('')
        props.refreshIndex(conversation, obj.data)
      } else {
        alert(obj.status)
      }
    })
  }

  function renderMessages() {
    // let messages = [...conversation.attributes.messages.data, ...conversation.attributes.messages.data, ...conversation.attributes.messages.data, ...conversation.attributes.messages.data]
    let messages = conversation.attributes.messages.data
    return messages.map((message, i) => {
      return (
        <Message message={message} key={i}/>
      )
    })
  }

  function calculateHeight() {
    if (props.device === 'mobile') {
      return '52vh'
    }

    if (props.device === 'tablet') {
      if (props.orientation === 'portrait') {
        return '81vh'
      } else {
        return '74vh'
      }
    }
    // default desktop
    return '79vh'
  }


  return (
    <Container fluid className="p-0 m-0" ref={conversationContainer}>
      <Container fluid className="p-0 m-0" style={{overflowY: 'scroll', height: calculateHeight()}} ref={messageContainer}>
        {renderMessages()}
      </Container>
      <Container fluid className={`${props.device === 'mobile' ? 'm-0 p-0' : ''}`} onClick={(e) => {e.stopPropagation()}}>
        <MessageComposeControls
          messageBody={messageBody}
          onChange={(e) => {setMessageBody(e)}}
          loading={loading}
          onSend={sendMessageHandler}
        />
      </Container>
    </Container>
  )
}

export default Conversation;