import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom'
import './BuyNumbersContainer.css';
import CountryDropdown from '../../Dropdowns/CountryDropdown/CountryDropdown'
import Button from '../../AppButtons/Button/Button'
import {getPhoneCostsForCountry} from '../../../Services/Public'
import {getAvailableNumbersForCountry} from '../../../Services/Twilio'
import PhoneNumberListItem from '../../PhoneNumberListItem/PhoneNumberListItem'
import {scrollToTopOfPage} from '../../../Utilities/DOMutils/DOMutils'
import PhoneNumberBuyConfirmation from '../../PhoneNumberBuyConfirmation/PhoneNumberBuyConfirmation'
import {provisionPhoneNumberForUser} from '../../../Services/User'
import PhoneNumberBuySuccess from '../../PhoneNumberBuySuccess/PhoneNumberBuySuccess'
import PhoneNumberBuyFailure from '../../PhoneNumberBuyFailure/PhoneNumberBuyFailure'

const BuyNumbersContainer = props => {
  let [loading, setLoading] = useState(false)
  let [selectedCountry, setSelectedCountry] = useState(null)
  let [activeStep, setActiveStep] = useState(1)
  let [numberRentalCost, setNumberRentalCost] = useState(null)
  let [phoneNumbers, setPhoneNumbers] = useState(null)
  let [selectedNumber, setSelectedNumber] = useState(null)
  let [purchasedNumber, setPurchasedNumber] = useState(null)
  let [numberPurchaseError, setNumberPurchaseError] = useState(null)

  useEffect(() => {
    scrollToTopOfPage()
  }, [selectedNumber])

  const numberPurchaseHandler = (numberObj) => {
    if (numberObj.sms_pricing &&  numberObj.sms_pricing.inbound_cost) {
      let purchaseOptions = {
        number: numberObj.number,
        number_type: numberObj.sms_pricing.inbound_cost.number_type,
        quoted_price: numberObj.monthly_cost,
        country_code: selectedCountry.value,
        incoming_sms_cost: numberObj.sms_pricing.inbound_cost.current_price,
        outgoing_sms_cost: numberObj.sms_pricing.average_outbound_cost
      }
      setLoading(true)
      provisionPhoneNumberForUser(purchaseOptions).then(res => res.json())
      .then(obj => {
        if (obj.code === 200) {
          setPurchasedNumber(purchaseOptions)
          setActiveStep(4)
          setLoading(false)
        } else {
          setNumberPurchaseError(obj.status)
          setLoading(false)
          setActiveStep(4)
        }
      })
    } else {
      alert('cannot purchase number, sorry')
    }
  }
  
  const renderActiveStep = () => {
    switch(activeStep) {
      case 1:
        return renderCountrySelection()
      case 2:
        return renderPhoneNumberSelection()
      case 3: 
        return (
          <PhoneNumberBuyConfirmation 
            selectedNumber={selectedNumber}
            onConfirm={numberPurchaseHandler}
            onDeny={backToNumberSelectionHandler}
            loading={loading}
          />
        )
      case 4: 
        if (purchasedNumber) {
          return <PhoneNumberBuySuccess purchasedNumber={purchasedNumber}/>
        } else {
          return <PhoneNumberBuyFailure error={numberPurchaseError}/>
        }
    }
  }

  const backToNumberSelectionHandler = () => {
    setActiveStep(2)
    setLoading(false)
    setSelectedNumber(null)
  }


  const renderCountrySelection = () => {
    return (
      [
        <div className="buy-numbers-container-heading-wrapper">
          <h1 className="buy-numbers-container-heading">Provision a phone number</h1>
          <h2 className="buy-numbers-container-subheading">Numbers cost a monthly rental fee depending on the region</h2>
        </div>,
        <div className="buy-numbers-container-country-select-wrapper">
          <CountryDropdown device={props.device} onSelect={(e) => { setSelectedCountry(e) }}/>
        </div>,
        <div className="buy-numbers-button-wrapper">
          <Button clicked={countrySelectionHandler} title="Next" name={`next-button ${loading ? 'loading' : ''}`} disabled={ selectedCountry !== null ? false : true }/>
        </div>
      ]
    )
  }

  const renderRentalCost = () => {
    let localNumberPricing = numberRentalCost.pricing.find(n => n.number_type == 'local')
    let mobileNumberPricing = numberRentalCost.pricing.find(n => n.number_type == 'mobile')
    if (localNumberPricing && mobileNumberPricing) {
      return `local numbers cost $${localNumberPricing.current_price}USD and mobile numbers cost $${mobileNumberPricing.current_price} per month`
    }

    if (localNumberPricing) {
      return `numbers cost $${localNumberPricing.current_price}USD per month`
    }

    if (mobileNumberPricing) {
      return `numbers cost $${mobileNumberPricing.current_price}USD per month`
    }
  }

  const renderPhoneNumbers = () => {
    return phoneNumbers.map((n, i) => {
      return (
        <PhoneNumberListItem
          number={n}
          key={i}
          onClick={numberSelectionHandler}
        />
      )
    })
  }

  const backToCountrySelectionHandler = () => {
    setActiveStep(1)
    setLoading(false)
    setSelectedCountry(null)
    setNumberRentalCost(null)
    setPhoneNumbers(null)
  }


  const numberSelectionHandler = (number) => {
    setSelectedNumber(number)
    setActiveStep(3)
  }
  const renderPhoneNumberSelection = () => {
    return (
      [
        <div className="buy-numbers-container-heading-wrapper">
          <h1 className="buy-numbers-container-heading">Provision a phone number</h1>
          <h2 className="buy-numbers-container-subheading">For {selectedCountry.label}, {renderRentalCost()}</h2>
        </div>,
        <div className="d-flex flex-wrap justify-content-center mb-4 mt-4">
          {renderPhoneNumbers()}
        </div>,
        <div className="buy-numbers-button-wrapper">
          <Button clicked={backToCountrySelectionHandler} title="Back" name={`next-button ${loading ? 'loading' : ''}`} disabled={ selectedCountry !== null ? false : true }/>
        </div>  
      ]
    )
  }

  const countrySelectionHandler = () => {
    setLoading(true)
    if (selectedCountry) {
      getPhoneCostsForCountry(selectedCountry.value, 'rental_cost').then(res => res.json())
      .then(obj => {
        if (obj.currency) {
          setNumberRentalCost(obj)
          getAvailableNumbersForCountry(selectedCountry.value).then(res => res.json())
          .then(obj => {
            if (obj.local_numbers || obj.mobile_numbers) {
              setLoading(false)
              let allNumbers = [...obj.local_numbers, ...obj.mobile_numbers]
              setPhoneNumbers(allNumbers)
              setActiveStep(2)
            }
          })
        } else {
          setLoading(false)
          alert('An error occured, please try again later')
        }
      })
    } else {
      alert('select a country first!')
    }
  }

  return (
    <div className="buy-numbers-container">
      {renderActiveStep()}
    </div>
  )
}

export default BuyNumbersContainer;