export const initialState = {
  app: {
    currentUser: {
      authentication: {
        loggedIn: false, 
        userId: null, 
        name: null,
        paymentMethodAdded: null,
        signupFeePaid: null, 
        paymentMethodValid: null, 
        isProblematic: null,
        agreedToTerms: false
      },
      credits: {
        amount: 0
      }
    }
  },
}