import {logOut} from '../../Services/User'

export const getAuthenticationDataFromLocalStorage = () => {
  let userId = localStorage.getItem('userId')
  let name = localStorage.getItem('name')
  return {
    name: name,
    user_id: userId,
  }
}

export const setAuthenticationDataToLocalStorage = (obj = {}) => {
  localStorage.setItem('userId', obj.user_id)
  localStorage.setItem('name', obj.name)
}

export const clearDataAndSignOut = () => {
  console.log('clearing data')
  logOut(getAuthenticationDataFromLocalStorage().userId).then(res => res.json())
  .then(obj => {
    localStorage.clear()
    window.location ="/"
  })
  
}
