import React, {useState, useEffect} from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { isMobile, isTablet, isBrowser, withOrientationChange } from "react-device-detect"
import './App.css';
import {scrollToTopOfPage} from './Utilities/DOMutils/DOMutils'
import Navbar from './Components/Navbars/Navbar/Navbar'
import LandingContainer from './Components/Paths/LandingContainer/LandingContainer'
import LoginContainer from './Components/Paths/LoginContainer/LoginContainer'
import {connect} from 'react-redux'
import {getAuthenticationDataFromLocalStorage} from './Utilities/LocalStorage/UserAuthStorage'
import {getUserInfo} from './Services/User'
import CostEstimation from './Components/CostEstimation/CostEstimation'
import BuyNumbersContainer from './Components/Paths/BuyNumbersContainer/BuyNumbersContainer'
import LoadAccount from './Components/LoadAccount/LoadAccount'
import UserPhoneNumbers from './Components/UserPhoneNumbers/UserPhoneNumbers'
import PhoneNumberConversationsContainer from './Components/Paths/PhoneNumberConversationsContainer/PhoneNumberConversationsContainer'
import NewPhoneNumberConversation from './Components/Paths/NewPhoneNumberConversation/NewPhoneNumberConversation'
import ErrorBox from './Components/ErrorBox/ErrorBox'
import Account from './Components/Paths/Account/Account'
import Support from './Components/Paths/Support/Support'
import Explore from './Components/Paths/Explore/Explore'
import Footer from './Components/Footer/Footer'
import {setCurrentUserAttribs} from './Actions/User/DispatchUserState'
import Spinner from './Components/Loaders/Spinner/Spinner'
import AddedToSlack from './Components/Paths/AddedToSlack/AddedToSlack'
import LoginModal from './Components/AppModals/LoginModal/LoginModal'


function App(props) {
  let [device, setDevice] = useState('')
  let [orientation, setOrientation] = useState('')
  let [authenticating, setAuthenticating] = useState(false)
  let user = getAuthenticationDataFromLocalStorage()

  useEffect(() => {
    detectDevice()
    scrollToTopOfPage()
    detectOrientation()
  }, [props.isPortrait, props.isLandscape])

  useEffect(() => {
    // for auth related rendering
    authenticationHandler()
  }, [user.user_id])

  const authenticationHandler = () => {
    setAuthenticating(true)
    if (user && user.name && user.user_id) {
      getUserInfo(user.user_id).then(res => res.json())
      .then(obj => {
        setAuthenticating(false)
        if (obj && obj.data && obj.data.type === 'user') {
          let currentUser = obj.data
          setCurrentUserAttribs(currentUser, props.setCurrentUser)
        } else {
          alert(obj.status)
        }
      })
    }
  }

  function detectDevice() {
    if (isMobile && !isTablet && !isBrowser) {
      setDevice('mobile')
    } else if (isMobile && isTablet && !isBrowser) {
      setDevice('tablet')
    } else if (!isMobile && !isTablet && isBrowser) {
      setDevice('desktop')
    }
  }

  function detectOrientation() {
    if (props.isPortrait) {
      setOrientation('portrait')
    } else if (props.isLandscape) {
      setOrientation('landscape')
    }
  }

  function computeCssStyles() {
    return `App-${device} App-${orientation}`
  }

  function showErrors() {
    if (props.loggedInUserData.authentication.userId) {
      return (
        <ErrorBox userMeta={props.loggedInUserData}/>
      )
    }
  }

  function rootPathContext() {
    // if a user is in local storage
    if (user.user_id) {
      // if a user is in redux show getting started
      if (props.loggedInUserData.authentication.userId) {
        return (
          <Route exact path='/' render={(props) => <LoginContainer device={device} orientation={orientation} />} />
        )
      } else {
        // when authenticating show spinner
        if (authenticating) {
          return <div className="login-container-wrapper"><Spinner size="big"/></div>
        } else {
          // if not authenticating and no user is in redux, show the landing page
          return (
            <Route exact path='/' render={(props) => <LandingContainer device={device} orientation={orientation} />} />
          )
        }
      }
    } else {
      return (
        <Route exact path='/' render={(props) => <LandingContainer device={device} orientation={orientation} />} />
      )
    }
  }

  function showLoginModal() {
    let showModal = props.site.global.loginOverlayVisible
    if (showModal) {
      return (
        <LoginContainer showAsModal device={device} orientation={orientation} />
      )
    }
  }

  return (
    <div className={`App ${computeCssStyles()}`}>
      <BrowserRouter>
        <Navbar 
          currentUser={props.loggedInUserData} 
          device={device} 
          orientation={orientation}
          toggleLoginOverlay={props.loginOverlayHandler}
        />
        {showErrors()}
        {showLoginModal()}
        <Switch>
          {rootPathContext()}
          <Route exact path='/explore' render={(props) => <Explore device={device} orientation={orientation} />} />
          <Route exact path='/get-started' render={(props) => <LoginContainer device={device} orientation={orientation} />} />
          <Route exact path='/cost-estimation' render={(props) => <CostEstimation device={device} orientation={orientation} />} />
          <Route exact path='/phone-numbers/buy' render={(props) => <BuyNumbersContainer device={device} orientation={orientation} />} />
          <Route exact path='/self/support' render={(props) => <Support device={device} orientation={orientation} />} />
          <Route exact path='/self/load-account' render={(props) => <LoadAccount device={device} orientation={orientation} />} />
          <Route exact path='/self/account-settings' render={(props) => <Account device={device} orientation={orientation} />} />
          <Route exact path='/self/account-settings/slack-integrations/create' render={(props) => <AddedToSlack device={device} orientation={orientation} {...props}/>} />
          <Route exact path='/self/phone-numbers' render={(props) => <UserPhoneNumbers device={device} orientation={orientation} />} />
          <Route exact path='/self/phone-numbers/:id/conversations' render={(props) => <PhoneNumberConversationsContainer device={device} orientation={orientation} {...props}/>} />
          <Route exact path='/self/phone-numbers/:id/conversations/new' render={(props) => <NewPhoneNumberConversation device={device} orientation={orientation} {...props}/>} />
        </Switch>
        <Footer device={device}/>
      </BrowserRouter>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    loggedInUserData: state.appReducer.app.currentUser,
    site: state.siteReducer.site
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setCurrentUser: (userObj) => {dispatch({
      type: 'setCurrentlyLoggedInUser',
      value: userObj
    })},
    loginOverlayHandler: (bool) => {dispatch({
      type: 'toggleLoginOverlay',
      value: bool
    })}
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withOrientationChange(App));
