import React from 'react';
import './NavbarLinks.css';
import {Link} from 'react-router-dom'
import {navbarData, authenticatedNavbarData} from '../../../Constants/SiteData/Navbar'
import {clearDataAndSignOut} from '../../../Utilities/LocalStorage/UserAuthStorage'
import {scrollToElem, smoothScrollIntoView} from '../../../Utilities/DOMutils/DOMutils'

function NavbarLinks(props) {

  function handleScrollIntoViewLinkClick(e, l) {
    if (props.onClick)  {
      props.onClick()
    }
    smoothScrollIntoView(l, props.device)
  }

  function handleGetStartedClick() {
    props.toggleLoginOverlay(true)
    if (props.onClick)  {
      props.onClick()
    }
  }

  const renderUnAuthenticatedLinks = () => {
    return navbarData.links.list.map((l, i) => {
      if (l.hasPath) {
        if (l.id === 'loginPath') {
          return (
            <button 
              onClick={handleGetStartedClick} 
              className="login" 
              to={l.path}>{l.label}
            </button>
          )
        } else {
          return (
          <Link onClick={props.onClick} className="navbarlinks-link" to={l.path}>{l.label}</Link>
          )
        }
      } else {
        return (
          <Link to="/" onClick={ (e) => { handleScrollIntoViewLinkClick(e, l) }} className="navbarlinks-link">{l.label}</Link>
        )
      }
    })
  }

  const renderAuthenticatedLinks = () => {
    let currentUserCredits = props.currentUser.credits
    return authenticatedNavbarData.links.list.map((l, i) => {
      if (l.id === 'logoutPath') {
        return (
          <Link onClick={() => {clearDataAndSignOut()}} className="navbarlinks-link" to={l.path}>{l.label}</Link>
        )
      } else if (l.id === 'loadCredits') {
        return (
          <Link onClick={props.onClick} className="navbarlinks-link" to={l.path}>{l.label} (${currentUserCredits.amount})</Link>
        )
      } else {
        return (
          <Link onClick={props.onClick} className="navbarlinks-link" to={l.path}>{l.label}</Link>
        )
      }
    })
  }

  const renderLoginContext = () => {

    if (props.currentUser.authentication.loggedIn) {
      return renderAuthenticatedLinks()
    } else {
      return renderUnAuthenticatedLinks()
    }
  }
  
  return (
    <div className="navbarlinks-wrapper">
     {renderLoginContext()}
    </div>
  );
}

export default NavbarLinks;
