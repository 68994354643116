import React, {useEffect, useState} from 'react';
import './PhoneNumberConversationsContainer.css';
import ButtonAnchor from '../../AppButtons/ButtonAnchor/ButtonAnchor'
import {listConversations, deleteConversation, updateConversationTitle, showConversation, makePhoneCall} from '../../../Services/User'
import ConversationListItem from '../../ConversationListItem/ConversationListItem'
import {connect} from 'react-redux'
import {getUserInfo} from '../../../Services/User'
import {getAuthenticationDataFromLocalStorage} from '../../../Utilities/LocalStorage/UserAuthStorage'
import {addUniqueNodeToEndOfArray} from '../../../Utilities/ArrayUtilities/ArrayUtils'
import FullSizeConversationIndex from '../../FullSizeConversationIndex/FullSizeConversationIndex'
import {scrollToTopOfPage} from '../../../Utilities/DOMutils/DOMutils'
import {setCurrentUserAttribs} from '../../../Actions/User/DispatchUserState'
import {showOwnedPhoneNumbers} from '../../../Services/User'
import Alert from 'react-bootstrap/Alert'
import Spinner from '../../Loaders/Spinner/Spinner'
import {Colors} from '../../../Constants/Style/Colors'

const PhoneNumberConversationsContainer = props => {
  let phoneNumberId = props.match.params.id

  let [conversations, setConversations] = useState([])
  let [phoneNumber, setPhoneNumber] = useState(null)
  let [loadingConversations, setLoadingConversations] = useState(false)

  let user = getAuthenticationDataFromLocalStorage()

  useEffect(() => {
    fetchPhoneNumber()
    fetchConversations()
    refetchUserData()
    scrollToTopOfPage()
  }, [])

  function fetchPhoneNumber() {
    showOwnedPhoneNumbers(phoneNumberId).then(res => res.json())
    .then(obj => {
      if (obj.data) {
        setPhoneNumber(obj.data)
      } else {
        alert(obj.status)
      }
    })
  }

  function fetchConversations() {
    setLoadingConversations(true)
    listConversations(phoneNumberId).then(res => res.json())
    .then(obj => {
      setLoadingConversations(false)
      if (obj.data) {
        setConversations(obj.data)
      } else {
        alert(obj.status)
      }
    })
  }

  function fetchConversation(conversationId) {
    showConversation(phoneNumberId, conversationId).then(res => res.json())
    .then(obj => {
      if (obj.data) {
        let refreshedConversation = obj.data
        let updatedConversationIndex = conversations.map((c) => {
          if (c.id === refreshedConversation.id) {
            return {
              ...refreshedConversation,
            }
          } else {
            return c
          }
        })
        setConversations(updatedConversationIndex)
      } else {
        alert(obj.status)
      }
    })
  }

  function refetchUserData() {
    getUserInfo(user.user_id).then(res => res.json())
    .then(obj => {
      if (obj && obj.data && obj.data.type === 'user') {
        let currentUser = obj.data
        setCurrentUserAttribs(currentUser, props.refreshUserDetails)
      }
    })
  }

  function handleConversationDelete(conversationId) {
    if (window.confirm('Deleting this conversation removes all its messages, this cannot be undone. Continue?')) {
      deleteConversation(phoneNumberId, conversationId).then(res => res.json())
      .then(obj => {
        if (obj.code === 200) {
          fetchConversations()
        } else {
          alert(obj.status)
        }
      })
    }
  }

  function handleConversationTitleUpdate(phoneNumberId, conversationId, title) {
    updateConversationTitle(phoneNumberId, conversationId, {title: title}).then(res => res.json())
    .then(obj => {
      if (obj.data) {
        updateConversationsIndex(obj.data)
      } else {
        alert(obj.status)
      }
    })
  }

  function updateConversationsIndex(conversation) {
    let updatedConversationIndex = conversations.map((c) => {
      if (c.id === conversation.id) {
        return {
          ...conversation
        }
      } else {
        return c
      }
    })
    setConversations(updatedConversationIndex)
  }

  function updateConversationMessages(conversation, sentMessage) {
    refetchUserData()
    let updatedConversationIndex = conversations.map((c) => {
      if (c.id === conversation.id) {
        return {
          ...c,
          attributes: {
            ...c.attributes,
            messages: {
              ...c.attributes.messages,
              data: addUniqueNodeToEndOfArray(sentMessage, [...conversation.attributes.messages.data])
            } 
          }
        }
      } else {
        return c
      }
    })
    setConversations(updatedConversationIndex)
  }

  function handlePhoneCall(options) {
    makePhoneCall(phoneNumberId, options).then(res => res.json())
    .then(obj => {
      alert(obj.status)
    })
  }

  function renderConversations() {
    if (!loadingConversations) {
      if (conversations.length > 0) {
        return conversations.map((c, i) => {
          return (
            <ConversationListItem 
              key={i} 
              phoneNumberId={phoneNumberId} 
              conversation={c}
              updateMessageIndex={updateConversationMessages}
              onDelete={handleConversationDelete}
              onTitleUpdate={handleConversationTitleUpdate}
              updateConversation={fetchConversation}
              onCall={handlePhoneCall}
              device={props.device}
              orientation={props.orientation}
              loading={loadingConversations}
            />
          )
        })
      } else {
        return (
          <Alert variant='warning' className="text-center m-2">
            No conversations, yet.
          </Alert>
        )
      }
    } else {
      return <Spinner size="big" color={Colors.primary}/>
    }
  }

  function renderConversationUiContext() {
    if (props.device === 'desktop' || props.device === 'tablet') {
      return (
        <FullSizeConversationIndex
          phoneNumberId={phoneNumberId} 
          conversations={conversations}
          updateMessageIndex={updateConversationMessages}
          onDelete={handleConversationDelete}
          onTitleUpdate={handleConversationTitleUpdate}
          updateConversation={fetchConversation}
          onCall={handlePhoneCall}
          device={props.device}
          orientation={props.orientation}
          phoneNumber={phoneNumber}
          loading={loadingConversations}
        />
      )
    } else {
      return renderConversations()
    }
  }

  function renderNewConversationButtonContext() {
    if (props.device === 'mobile') {
      return (
        <div className="user-phone-numbers-buy-wrapper">
          <ButtonAnchor 
            url={`/self/phone-numbers/${phoneNumberId}/conversations/new`}
            label={`New Conversation ${phoneNumber ? `With: ${phoneNumber.attributes.number}` : ''}`}
          />
        </div>
      )
    }
  }
  return (
    <div>
      {renderNewConversationButtonContext()}
      <div>
        {renderConversationUiContext()}
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
  }
}

const mapDispatchToProps = dispatch => {
  return {
    refreshUserDetails: (userObj) => {dispatch({
      type: 'setCurrentlyLoggedInUser',
      value: userObj
    })}
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PhoneNumberConversationsContainer);