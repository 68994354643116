import {initialState} from '../InitialStates/Site/InitialState'

export const siteReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'toggleLoginOverlay':
      return {
        ...state, 
        site: {
          ...state.site, 
          global: {
            ...state.site.global, 
            loginOverlayVisible: action.value
          }
        }
      }
    default: 
    return state
  }
}

export default siteReducer