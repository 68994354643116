import React from 'react';
import './ReloadAccountDropdown.css';
import Select from 'react-select'

const ReloadAccountDropdown = props => {

  const options = [
    { value: '1000', label: '$10 USD' },
    { value: '2000', label: '$20 USD' },
    { value: '3000', label: '$30 USD' },
    { value: '4000', label: '$40 USD' },
    { value: '5000', label: '$50 USD' },
  ]

  const handleChange = (e) => {
    props.onSelect(e)
  }

  const renderContext = () => {
    if (props.device === 'mobile') {
      return (
        <Select 
          onChange={handleChange} 
          options={options} 
          name="Select amount"
          isClearable={true}
          isSearchable={false}
        />
      )
    } else {
      return (
        <Select 
          onChange={handleChange} 
          options={options} 
          isClearable={true}
          isSearchable={true}
          name="Select amount"
        />
      )
    }
  }
  return (
    renderContext()
  )
}

export default ReloadAccountDropdown;