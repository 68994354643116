export const landingPageData = {
  intro: {
    blocks: [
      'Meet your new Business Phone',
      "Lenny is a secure, international telecom platform that you can use for calling and texting your clients, without breaking the bank.",
      "How it works",
      "You can provision as many phone numbers as you like and they can be used immediately. When you are done using a number, you can get rid of it anytime. No strings attached",
      "One account for all your phone numbers",
      "Since Lenny diverts incoming and outgoing phone calls and messages to your cell phone, you can have as many phone numbers as you like and manage it all from one device. This way, people you communicate with won’t know your personal phone number."
    ]
  },
  pricing: {
    blocks: [
      'Our pricing is simple. You pay a one-time sign up fee of $5.49, and its pay-as-you-go from there on out.',
      'So for example, if you buy a Canadian 647 number; you will pay $1 per month to hold that number and 8 cents per message recieved and sent.',
      'Since you are prepaying, there are no surprises. You use what you paid for.'
    ]
  },
  testimonials: {
    blocks: [
      'We are new, so we don’t have testimonials yet. But give us a chance and we promise to rock your world!'
    ]
  }
}