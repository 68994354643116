export const getApiRoot = () => {
  const stagingRoot = "http://api.lenny.ngrok.io";
  const productionRoot = "https://api.lenny.mobi";
  return productionRoot;
};

export const getWebsocketRoot = (userId) => {
  const stagingRoot = `ws://api.lenny.ngrok.io/api/core/v1/users/${userId}/cable`;
  const productionRoot = `wss://api.lenny.mobi/api/core/v1/users/${userId}/cable`;
  return productionRoot;
};