import React from 'react';
import './ButtonAnchor.css';
import { Link } from 'react-router-dom';

const ButtonAnchor = props => {
  const resolveClick =() => {
    if (props.clickHandler) {
      props.clickHandler()
    }
  }
  return (
    <Link
      className={`btn-anchor ${props.classN ? props.classN : ''}`}
      to={props.url}
      onClick={resolveClick}
    >{props.label}</Link>
  )
}

export default ButtonAnchor;