import React from 'react';
import './Pricing.css';
import calculator from '../../../Assets/LandingPage/shared/pricing-calculator.png'
import money from '../../../Assets/LandingPage/shared/money-flying.svg'
import {Link} from 'react-router-dom'

const Pricing = props => {
  return (
    <div className="explore-pricing-wrapper">
      <img src={money} className="explore-pricing-money"/>
      <div className="explore-pricing-header-wrapper">
        <h1 className="explore-pricing-heading">Our pricing is simple.</h1>
        <h2 className="explore-pricing-subheading">We believe in a transparent and predictable pricing model.</h2>
        <h2 className="explore-pricing-subheading">Take a look at our pricing calculator, It shows you the monthly cost of phone numbers and their usage charges by country.</h2>
      </div>
      <Link to="/cost-estimation">
        <img src={calculator} className="explore-pricing-calculator"/>
      </Link>
    </div>
  )
}

export default Pricing;