import React from 'react';
import './Spinner.css';

const Spinner = props => {
  const color = props.color ? props.color : '#f4faff'
  return (
    <div className="spinner-container">
      <div className={`loadingio-spinner-eclipse ${props.size}`}>
        <div className="ldio"><div style={{ boxShadow: `0 2px 0 0 ${color}` }}></div></div>
      </div>
    </div>
  )
}

export default Spinner;