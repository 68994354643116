import React, {useEffect, useState} from 'react';
import './AddedToSlack.css';
import {setAccountSlackIntegration} from '../../../Services/User'
import Jumbotron from 'react-bootstrap/Jumbotron'
import Button from 'react-bootstrap/Button'
import {Link} from 'react-router-dom'

const AddedToSlack = props => {

  let [tempCode, setTempCode] = useState('')
  let [accessDenied, setAccessDenied] = useState(false)
  let [createdIntegration, setCreatedIntegration] = useState(null)

  useEffect(() => {
    extractCodeFromUrl()
  }, [])

  useEffect(() => {
    if (tempCode.length > 0) {
      setAccountSlackIntegration({tempCode: tempCode}).then(res => res.json())
      .then(obj => {
        if (obj.data) {
          setCreatedIntegration(obj.data)
        } else {
          // if slack does not exchange the temp code for an auth token
          alert(obj.status)
        }
      })
    }
  }, [tempCode])

  function extractCodeFromUrl() {
    let positiveMatch = window.location.search.match(/code=(.*)&/)
    if (positiveMatch) {
      let temporaryCode = positiveMatch[1]
      setTempCode(temporaryCode)
    } else {
      // if the user clicks on deny access on slack's end
      setAccessDenied(true)
    }
  }

  function renderIntegrationStatus() {
    if (accessDenied) {
      return ('Access was not granted, integration not added')
    } else {
      if (createdIntegration) {
        return ('Integration added successfully')
      } else {
        return ('Integration could not be created due to an error')
      }
    }
  }

  return (
    <Jumbotron>
      <h1>Slack Integration</h1>
      <p>
        {renderIntegrationStatus()}
      </p>
      <p>
        <Link className="btn btn-primary" to="/self/account-settings">View Slack Integrations</Link>
      </p>
    </Jumbotron>
  )
}

export default AddedToSlack;