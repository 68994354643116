import moment from 'moment';

export const showElapsedTimeFromCurrentTime = (time, accuracy = 'minute') => {
  return moment(time).startOf(accuracy).fromNow();
}

export const showDistanceInTimeFromNow = timeStamp => {
  return moment(timeStamp).fromNow();
};

export const formatDate =(timestamp) => {
  return moment(timestamp).format("MMMM Do YYYY")
}

export const showHoursAndMinutes = (timestamp) => {
  return moment(timestamp).format('LT')
}

export const formatTimestamp = (timestamp) => {
  return `${formatDate(timestamp)} at ${showHoursAndMinutes(timestamp)}`
}
