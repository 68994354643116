import React from 'react';
import './RealTime.css';
import iphone from '../../../Assets/LandingPage/shared/realtime-iphone.png'
import AppStoresGroup from '../../AppButtons/AppStoresGroup/AppStoresGroup'

const RealTime = props => {
  return (
    <div className="real-time-wrapper">
      <div className="real-time-heading-wrapper">
        <h1 className="real-time-heading">A real-time platform</h1>
        <h2 className="real-time-subheading">Our conversation portal updates automatically as messages come in, so you won’t miss a beat.</h2>
      </div>
      <img src={iphone} className='real-time-image'/>
      <AppStoresGroup/>
    </div>
  )
}

export default RealTime;