import React, {useEffect, useState} from 'react';
import './NewPhoneNumberConversation.css';
import {showOwnedPhoneNumbers} from '../../../Services/User'
import {createConversation} from '../../../Services/User'
import NewConversation from '../../NewConversation/NewConversation'
import ButtonAnchor from '../../AppButtons/ButtonAnchor/ButtonAnchor'
import PhoneNumberListItem from '../../PhoneNumberListItem/PhoneNumberListItem'

const NewPhoneNumberConversation = props => {
  let phoneNumberId = props.match.params.id

  let [outgoingPhoneNumber, setOutgoingPhoneNumber] = useState(null)
  let [createdConversation, setCreatedConversation] = useState(null)
  let [sending, setSending] = useState(false)


  useEffect(() => {
    fetchPhoneNumberDetails()
  }, [])

  function fetchPhoneNumberDetails() {
    showOwnedPhoneNumbers(phoneNumberId).then(res => res.json())
    .then(obj => {
      if (obj.data) {
        setOutgoingPhoneNumber(obj.data)
      } else {
        alert(obj.status)
      }
    })
  }

  function renderPhoneDetails() {
    if (outgoingPhoneNumber) {
      return (
        <div className="new-phone-number-conversation-outgoing-number">
          <div className="phone-number-list-item-wrapper">
            <p className="phone-number-list-item-number">sending from: {outgoingPhoneNumber.attributes.number}</p>
            <p className="phone-number-list-item-footer-permsg">${outgoingPhoneNumber.attributes.incoming_message_cost} incoming</p>
            <p className="phone-number-list-item-footer-permsg">${outgoingPhoneNumber.attributes.outgoing_message_cost} outgoing</p>
          </div>
        </div>
      )
    }
  }

  function renderMessageSuccessContext() {
    let messages = createdConversation.attributes.messages.data
    if (messages.length > 0) {
      return "Message Sent"
    } else {
      return "Message could not be sent, please check if you have enough credits"
    }
  }

  function sendMessageHandler(e, options) {
    e.preventDefault()
    setSending(true)
    createConversation(outgoingPhoneNumber.id, options).then(res => res.json())
    .then(obj => {
      setSending(false)
      if (obj.data) {
        setCreatedConversation(obj.data)
      } else {
        alert(obj.status)
      }
    })
  }

  function renderContext() {
    if (createdConversation) {
      return (
        [
          <div className="user-phone-numbers-buy-wrapper">
            <p className="buy-numbers-container-subheading">{renderMessageSuccessContext()}</p>
          </div>,
          <div className="user-phone-numbers-buy-wrapper">
              <ButtonAnchor 
                url={`/self/phone-numbers/${phoneNumberId}/conversations`}
                label="View conversations"
              />
          </div>
        ]
      )
    } else {
      return (
        [
          <div>
            {renderPhoneDetails()}
          </div>,
          <NewConversation
            onSend={sendMessageHandler}
            loading={sending ? true : false}
          />
        ]
      )
    }
  }

  return (
    <div className="new-phone-number-conversation-wrapper">
      <div className="buy-numbers-container-heading-wrapper">
        <h1 className="buy-numbers-container-heading">Create new conversation</h1>
        <h2 className="buy-numbers-container-subheading">Please include the country dialing code for the recipient number or select the country from the dropdown</h2>
      </div>
      {renderContext()}
    </div>
  )
}

export default NewPhoneNumberConversation;