import React, { useState, useEffect } from 'react'
import './StripeCreditCard.css'
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement
} from '@stripe/react-stripe-js'
import { scrollToTopOfPage } from '../../Utilities/DOMutils/DOMutils'
import Button from '../AppButtons/Button/Button'
import ErrorBox from '../AppNotifications/ErrorBox/ErrorBox'

const StripeCreditCard = props => {
  const { device, orientation, paymentIntent, onSuccess } = props
  const [disabled, setDisabled] = useState(true)
  const [cardNumberComplete, setCardNumberComplete] = useState(false)
  const [cvcComplete, setCvcComplete] = useState(false)
  const [expiryComplete, setexpiryComplete] = useState(false)
  const [error, setError] = useState(null)
  const [succeeded, setSucceeded] = useState(false)
  const [processing, setProcessing] = useState(null)

  const stripe = useStripe()
  const elements = useElements()

  useEffect(() => {
    creditCardSubmissionAllowHandler()
  }, [cardNumberComplete, cvcComplete, expiryComplete])

  const useStyles = () => {
    return {
      base: {
        fontSize: device === 'mobile' ? '16px' : '18px',
        color: "black",
        letterSpacing: "0.025em",
        fontFamily: "Raleway, sans-serif",
        "::placeholder": {
          color: "white"
        },
        iconColor: 'white'
      },
      complete: {
        color: "white",
        // textShadow: "1px 1px #000"
      },
      invalid: {
        color: "red",
        iconColor: 'red'
      }
    }
  }

  const creditCardSubmissionAllowHandler = () => {
    if (cardNumberComplete && cvcComplete && expiryComplete) {
      setDisabled(false)
    } else {
      setDisabled(true)
    }
  }

  const handleSubmit = async e => {
    e.preventDefault()
    if (!stripe || !elements) {
      return
    }
    setProcessing(true)

    stripe.confirmCardSetup(paymentIntent, {
          payment_method: {
          card: elements.getElement(CardNumberElement),
        },
      })
      .then(result => {
        if (!result.error && result.setupIntent.status == 'succeeded') {
          setError(null)
          setProcessing(false)
          setSucceeded(true)
          onSuccess()
          scrollToTopOfPage()
        } else {
          setError(result.error.message)
          setProcessing(false)
        }
      })
  }

  const renderError = () => {
    if (error) {
      return <ErrorBox name="credit-card-error-msg" errorMsg={error} />
    }
  }

  return (
    <form className="credit-card-form" onSubmit={handleSubmit}>
      <div className="credit-card-input-container">
        <div className="credit-card-number-block">
          <CardNumberElement
            options={{
              style: useStyles(),
              placeholder: 'Card number',
              showIcon: true,
              iconStyle: 'solid'
            }}
            onChange={(e) => { e.complete ?  setCardNumberComplete(true) : setCardNumberComplete(false)}}
          />
        </div>
        <div className="credit-card-form-row">
          <div className="credit-card-expiry-block">
            <CardExpiryElement
              options={{ style: useStyles() }}
              onChange={(e) => { e.complete ?  setexpiryComplete(true) : setexpiryComplete(false)}}
            />
          </div>
          <div className="credit-card-cvc-block">
            <CardCvcElement
              options={{ style: useStyles() }}
              onChange={(e) => { e.complete ?  setCvcComplete(true) : setCvcComplete(false)}}
            />
          </div>
        </div>
      </div>
      {renderError()}
      <p className="credit-card-form-disclaimer">Credit cards are handled by <a target="_blank" href="https://stripe.com/about">Stripe</a>. We do not save your card details in our servers or databases.</p>
      <Button
        type="submit"
        name="credit-card-form-submit-btn"
        title="Add payment method"
        disabled={processing || disabled || succeeded}
      />
    </form>
  )
}

export default StripeCreditCard