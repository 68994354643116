import React, {useEffect} from 'react';
import './Support.css';
import {scrollToTopOfPage} from '../../../Utilities/DOMutils/DOMutils'

const Support = props => {

  useEffect(() => {
    scrollToTopOfPage()
  }, [])
  
  return (
    <div className="load-account-wrapper">
      <div className="load-account-heading-wrapper">
        <h1 className="load-account-heading">Support</h1>
      </div>
      <div className="email-support-button-wrapper">
        <a className="btn-anchor next-button" href="mailto:contact@restarone.com">Email Support</a>
      </div>
    </div>
  )
}

export default Support;