import { scroller } from 'react-scroll'

export const lockBodyScroll = () => {
  const elem = document.getElementsByTagName('body')[0];
  if (elem) elem.classList.add('no-scroll');
}

export const unlockBodyScroll = () => {
  const elem = document.getElementsByTagName('body')[0];
  if (elem) elem.classList.remove('no-scroll');
}

export const scrollToTopOfPage = () => {
  window.scrollTo(0, 0);
}

export const expandTextArea = textarea => {
  const offset = textarea.offsetHeight - textarea.clientHeight 
  textarea.style.height = 'auto'
  textarea.style.height = `${textarea.scrollHeight + offset}px`
}

export const scrollToBottom = (containerRef) => {
  containerRef.current.scrollTop = containerRef.current.scrollHeight;
}

export const scrollIntoView = (containerRef) => {
  containerRef.current.scrollIntoView({behavior: "smooth", block: "end"});
}

export const scrollToElem = (elem, scrollOffset = 0) => {
  setTimeout(() => {
    scroller.scrollTo(elem, {
      duration: 600,
      delay: 0,
      smooth: true,
      offset: -scrollOffset,
    })
  }, 200)
}

export const smoothScrollIntoView = (link, device) => {
  if (device == 'desktop') {
    scrollToElem(link.scrollTo, 50)
  }

  if (device == 'mobile') {
    scrollToElem(link.scrollTo, +100)
  }

  if (device == 'tablet') {
    scrollToElem(link.scrollTo, +100)
  }
}