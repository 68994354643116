import React from 'react';
import './EntryPoints.css';
import {Link} from 'react-router-dom'

const EntryPoints = props => {
  return (
    <div className="entrypoints-wrapper">
      <div className="entrypoints-heading-wrapper">
        <h1 className="entrypoints-heading">What would you like to do?</h1>
      </div>
      <div className="entrypoints-links-wrapper">
        <div>
          <Link className="entrypoints-link" to="/self/phone-numbers">View My Phone Numbers</Link>
        </div>
        <div>
          <Link className="entrypoints-link" to="/phone-numbers/buy">Get a Phone Number</Link>
        </div>
        <div>
          <Link className="entrypoints-link" to="/self/load-account">Load Account</Link>
        </div>
      </div>
    </div>
  )
}

export default EntryPoints;